import {GameState} from "../GameState";
import {utils} from "pixi.js";

export class Restore extends GameState {
    async onEnter() {
        const index = Math.floor(Math.random() * this.game.config.counties.length);
        this.game.settings.country =  this.game.config.counties[index];
        this.game.settings.level =  2;

        this.game.scene =  this.game.scenes.showScene('gamePlay');

        if (this.game.server.inProgress()) {
            this.game.scene.restore();
            this.game.fms.goTo('waitKick');
            this.game.scene.placeBetButton.visible = false;
            this.game.scene.placeBetButton.disable();
            this.game.scene.collectButton.disable();
            this.game.scene.betsLis.disable();
            this.game.scene.betButton.disable();
            this.game.scene.settingsButton.disable();
            this.game.scene.autoplayButton.disable();
            this.game.scene.infoButton.disable();

            if (this.game.settings.autoBet) {
                this.game.scene.autoplayButton.enable();
            }

            if (this.game.server.roundResult) {
                this.game.scene.collectButton.enable();
                this.game.scene.setWin(this.game.server.roundWin);
            } else {
                this.game.scene.gameProgressBar.first();
                this.game.scene.collectButton.disable();
            }
        }

        this.game.soundPlayer.playMainMusic();
    }

    async onExit() {
    }
}
