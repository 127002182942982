import {GameState} from "../GameState";

export class SettingGameSettings extends GameState {
    async onEnter() {
        this.game.popupManager.showPopup('Settings');
        this.game.scene.showBlur();
    }

    async onExit() {
        this.game.scene.hideBlur();
    }
}
