export class UrlParser {
    constructor() {
        this.params = new URLSearchParams(window.location.search);
    }

    get(key) {
        return this.params.get(key);
    }

    getAll() {
        let params = {};
        for (const [key, value] of this.params.entries()) {
            params[key] = value;
        }
        return params;
    }

    has(key) {
        return this.params.has(key);
    }
}
