import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {BasePopup} from "./BasePopup";
import {BetTextStyle} from "../configs/textStyles";

export class Bets extends BasePopup {
    constructor(game, name, onClose = () => {} ) {
        super(game, name, onClose);

        this.currentBetIndex = this.game.server.currentBetIndex;

        this.betsList = this.addChild(new BetsListButtons(this.game.server.betsList, this.game.server.currentBetIndex, this.changeBetIndex.bind(this)));
        this.betsList.x = this.bg.x + this.bg.width / 2 - this.betsList.width / 2;
        this.betsList.y = this.bg.y + this.bg.height / 2 - this.betsList.height / 2;

        this.confirmButton = this.game.objectFactory.createButton({
            default: 'ButtonNormal.png',
            hover: 'ButtonHover.png',
            pressed: 'ButtonSelected.png',
            disabled: 'ButtonDisabled.png',
        }, () => this.confirm(), 'Confirm', 0.6);
        this.confirmButton.x = this.bg.x + this.bg.width / 2 - this.confirmButton.width / 2;
        this.confirmButton.y = this.betsList.y + this.betsList.height  + 30;
        this.addChild(this.confirmButton);
    }

    changeBetIndex(index) {
        this.currentBetIndex = index;
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    confirm() {
        this.game.server.setBetIndex(this.currentBetIndex);
        this.close();
    }

    open() {
        super.open();
        this.currentBetIndex = this.game.server.currentBetIndex;
        this.betsList.selectBet(this.currentBetIndex);
    }

    close() {
        super.close();
        this.game.fms.goTo('waitingForBet');
    }
}

class BetsListButtons extends Container {
    constructor(bets, currentBetIndex, onChangeBet) {
        super();

        const spacing = 10;  // Расстояние между кнопками

        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 4; j++) {
                const index = i * 4 + j;
                if (index < bets.length) {
                    const bet = bets[index];
                    const button =  new BetButton(bet);
                    button.x = j * (button.width + spacing);
                    button.y = i * (button.height + spacing);
                    button.on('pointerdown', () => {
                        this.selectBet(index);
                        onChangeBet(index);
                    });
                    this.addChild(button);
                }
            }
        }

        this.selectBet(currentBetIndex);
    }

    selectBet(index) {
        this.children.forEach((child, i) => {
            child.setSelect(i === index);
        });
    }
}

class BetButton extends Container {
    constructor(bet) {
        super();

        this.bet = bet;

        this.bg = ObjectFactory.createSpriteFromSheet('BetBgDefault.png');
        this.addChild(this.bg);

        this.text = ObjectFactory.createText(bet, BetTextStyle);
        this.text.x = this.bg.width / 2;
        this.text.y = this.bg.height / 2;
        this.text.anchor.set(0.5);
        this.addChild(this.text);

        this.interactive = true;
        this.buttonMode = true;
        this.on('pointerdown', this.selected.bind(this));
        this.on('pointerup', this.hover.bind(this));
        this.on('pointerupoutside', this.normal.bind(this));
        this.on('pointerover', this.hover.bind(this));
        this.on('pointerout', this.normal.bind(this));

        this.isSelected = false;
    }

    selected() {
        this.bg.texture = ObjectFactory.getTextureFromSpriteSheet('BetBgSelected.png');
    }

    hover() {
        this.bg.texture = ObjectFactory.getTextureFromSpriteSheet(this.isSelected ? 'BetBgSelected.png' : 'BetBgHover.png');
    }

    normal() {
        this.bg.texture = ObjectFactory.getTextureFromSpriteSheet(this.isSelected ? 'BetBgSelected.png' : 'BetBgDefault.png');
    }

    setSelect(value) {
        this.isSelected = value;
        this.bg.texture = ObjectFactory.getTextureFromSpriteSheet(value ? 'BetBgSelected.png' : 'BetBgDefault.png');
    }
}
