export function getRandomNumber() {
    return Math.floor(Math.random() * 4);
}

export function roundToTwo(num) {
    return Number(num.toFixed(2));
}

export function formatCurrency(num) {
    return `$${roundToTwo(num)}`;
}

function findClosestValue(array, value) {
    let closest = -1;
    let closestDifference = Math.abs(value - array[0]);

    for (let i = 1; i < array.length; i++) {
        const difference = Math.abs(value - array[i]);
        if (difference < closestDifference) {
            closest = i;
            closestDifference = difference;
        }
    }

    return closest;
}

export function multiplyAndFindClosest(array, currentValue, multiplier) {
    const targetValue = currentValue * multiplier;
    return findClosestValue(array, targetValue);
}

