import {Container} from "pixi.js";
import {SmoothingProgress} from "../SmoothingProgress";
import {BaseGameScene} from "./BaseGameScene";

export class LoadingScene extends BaseGameScene {
    constructor(game) {
        super(game);

        this.content = this.addChild(new Container());

        this.logo = this.game.objectFactory.createSpriteFromSheet('CryptoGaming.png', 'assets/loading.json');
        this.logo.anchor.set(0.5);
        this.logo.scale.set(0.5);
        this.logo.x = this.game.config.screenSize.width / 2;
        this.logo.y = this.game.config.screenSize.height / 2 - this.logo.height * 0.3;
        this.content .addChild(this.logo);

        this.progressBar = this.game.objectFactory.createGameLoadingProgressBar();
        this.progressBar.setProgress(0);
        this.progressBar.x = this.game.config.screenSize.width / 2 - this.progressBar.width / 2;
        this.progressBar.y = 600;
        this.content.addChild(this.progressBar);

        this.smoothingProgress = new SmoothingProgress(this.progressHandler.bind(this), this.onCompleteLoading.bind(this));
    }

    setLoadingProgress(progress) {
        this.smoothingProgress.increase(progress * 100);
    }

    progressHandler(progress) {
        this.progressBar.setProgress(progress);
    }

    async onCompleteLoading() {
        this.content.destroy();
        await this.playLogo();
        this.game.fms.exit();
    }

    async playLogo() {
        const anim = this.game.objectFactory.createAnimation("4_logo/4_logo");

        anim.anchor.set(0.5);
        anim.x = this.game.config.screenSize.width / 2;
        anim.y = this.game.config.screenSize.height / 2;
        anim.animationSpeed = 0.5;
        anim.loop = false;
        anim.play();
        this.addChild(anim);

        return new Promise(resolve => {
            anim.onComplete = () => {
                anim.destroy();
                resolve();
            };
        });
    }
}
