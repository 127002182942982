import {BasePopup} from "./BasePopup";
import {ObjectFactory} from "../ObjectFactory";
import {Container} from "pixi.js";
import {multiplyAndFindClosest} from "../utils";
import {AutoplayListTextStyle, AutoplayVariantTextStyle} from "../configs/textStyles";

export class Autoplay extends BasePopup {
    constructor(...props) {
        super(...props);


        this.multiplesBet = ['Min', 2, 10, 25, 'Max'];
        this.bets = [];
        this.rounds = [5, 10, 20, 50, 100, '∞'];
        this.multipliersValues = [];
        this.roundsValues = [];

        const autoplayConfig = this.game.config.autoplay;
        let bet = autoplayConfig.minBet;

        this.bets.push(bet);

        while(bet < autoplayConfig.maxBet) {
            bet += autoplayConfig.step;

            this.bets.push(bet);
        }

        let value = 1;
        this.multipliersValues.push(value);

        while (value <= 1000) {
            value += 0.25;

            this.multipliersValues.push(value);
        }

        value = 1;
        this.roundsValues.push(value);

        while (value <= 1000) {
            value += 1;

            this.roundsValues.push(value);
        }

        this.roundsValues.push('∞');

        this.betsList = ObjectFactory.createListForAutoplaySettings(this.bets, 0, 'Bet');
        this.betsList.x = 437;
        this.betsList.y = 356;
        this.betsList.leftButton.x -= 6;
        this.betsList.rightButton.x += 6;
        this.betsList.on('change', (value) => {
            this.game.soundPlayer.playSWX('buttonClickSound');
        });
        this.addChild(this.betsList);
        this.multiplesBetsButtons = this.addChild(new Container());

        let buttonWidth = 53;

        this.multiplesBet.forEach((v, index) => {
            const label = typeof v === 'string' ? v : `X${v}`;
            const btn = this.createVariantButton(index, label, this.onClickMultiplierBet.bind(this), buttonWidth);

            btn.x = (buttonWidth + 2) * this.multiplesBetsButtons.children.length;

            this.multiplesBetsButtons.addChild(btn);
        });

        this.multiplesBetsButtons.x = this.betsList.x - this.multiplesBetsButtons.width / 2 ;
        this.multiplesBetsButtons.y = this.betsList.y + 25;

        buttonWidth = 40;
        this.roundsList = ObjectFactory.createListForAutoplaySettings(this.roundsValues, 0, 'Rounds');
        this.roundsList.x = 760;
        this.roundsList.y = 356;
        this.roundsList.leftButton.x -= 6;
        this.roundsList.rightButton.x += 6;
        this.roundsList.on('change', (value) => {
            this.game.soundPlayer.playSWX('buttonClickSound');
        });
        this.addChild(this.roundsList);
        this.roundsButtons = this.addChild(new Container());

        this.rounds.forEach((v, index) => {
            const btn = this.createVariantButton(index, v, this.onClickRound.bind(this), buttonWidth);

            btn.x = (buttonWidth + 2) * this.roundsButtons.children.length;

            this.roundsButtons.addChild(btn);
        })

        this.roundsButtons.x = this.roundsList.x - this.roundsButtons.width / 2 ;
        this.roundsButtons.y = this.roundsList.y + 25;

        const row = new Container();
        row.x = 292;
        row.y = 483;

        this.cashCheckBox = ObjectFactory.createCheckBox('Cash Out', false);
        this.cashCheckBox.on('change', this.onChangeCashOut.bind(this));
        row.addChild(this.cashCheckBox)

        this.mutiplierList = ObjectFactory.createList(this.multipliersValues.map(v => `x${v}`), 0, undefined, 'AutoplayCashValueBG.png', AutoplayListTextStyle);
        this.mutiplierList.x =  this.cashCheckBox.width + 10 +  this.mutiplierList.width / 2;
        this.mutiplierList.y = this.cashCheckBox.height / 2;
        this.mutiplierList.disable();
        this.mutiplierList.leftButton.x -= 6;
        this.mutiplierList.rightButton.x += 6;
        this.mutiplierList.on('change', (value) => {
            this.game.soundPlayer.playSWX('buttonClickSound');
        });

        row.addChild(this.mutiplierList);

        this.addChild(row);

        this.startButton = this.addChild(ObjectFactory.createSliceButton({
            texturesNames: {
                default: 'ButtonNormal.png',
                hover: 'ButtonHover.png',
                pressed: 'ButtonSelected.png',
                disabled: 'ButtonDisabled.png'
            },
            onClick: this.onClickStart.bind(this),
            bgScale: 0.75
        }));
        this.startButton.y = 483;
        this.startButton.x = this.game.config.screenSize.width / 2 + 8;
        const icon =ObjectFactory.createSpriteFromSheet('AutoplayStart.png')
        icon.anchor.set(0.5);
        icon.position.set(this.startButton.width / 2, this.startButton.height / 2);
        this.startButton.addChild(icon);
        this.addChild(this.startButton);
    }

    onClickStart() {
        this.game.settings.autoBet = true;
        this.game.settings.autoplay = {
            rounds: this.roundsList.getCurrent() === '∞' ? Infinity : this.roundsList.getCurrent(),
            bet: this.betsList.getCurrent(),
            cashOut: this.cashCheckBox.isChecked(),
            multiplier: this.multipliersValues[this.mutiplierList.getCurrentIndex()]
        };
        this.game.server.betsList = [this.game.settings.autoplay.bet];
        this.game.server.setBetIndex(0);
        this.game.scene.setAutoBetMode();
        this.close();
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    onChangeCashOut(value) {
        value ? this.mutiplierList.enable() : this.mutiplierList.disable();
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    createVariantButton(index, text, onClick, buttonWidth) {
        return ObjectFactory.createSliceButton({texturesNames: {
                default: 'SmallButtonDefault.png',
                hover: 'SmallButtonHover.png',
                disabled: 'SmallButtonDisabled.png',
                pressed: 'SmallButtonPressed.png',
            },
            text,
            onClick: () => onClick(index),
            nineSlicePlaneParameters: {top: 16, left: 16, right: 16, bottom: 16},
            textStyle: AutoplayVariantTextStyle,
            width: buttonWidth
        });
    }

    onClickMultiplierBet(index) {
        if (index === 0) {
            this.betsList.setIndex(0);
        } else if(index === this.multiplesBet.length - 1) {
            this.betsList.setIndex(this.bets.length - 1);
        } else {
            const nextIndex = multiplyAndFindClosest(this.bets, this.betsList.getCurrent(), this.multiplesBet[index]);

            this.betsList.setIndex(nextIndex);
        }
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    onClickRound(index) {
        const targetIndex = this.roundsValues.indexOf(this.rounds[index]);

        this.roundsList.setIndex(targetIndex);
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    close() {
        super.close();
        this.game.fms.goTo('waitingForBet');
    }
}




