import { AnimatedSprite } from 'pixi.js';
import { Button } from './Button.js';
import { Timer } from '../Timer.js';

export class BallButton extends Button {
    constructor(textures, onClick, text, animationFrames) {
        super({textures, onClick, text});

        this.animation = AnimatedSprite.fromFrames(animationFrames);
        this.animation.anchor.set(0.5);
        this.animation.x = this.sprite.width / 2;
        this.animation.y = this.sprite.height / 2;
        this.animation.visible = false;
        this.animation.animationSpeed = 0.5;
        this.animation.loop = true;
        this.addChild(this.animation);

        this.startAnimation();

        this.sprite.on('pointertap', this.stopAnimation.bind(this));
    }

    startAnimation() {
        this.animation.visible = true;
        this.animation.play();
        this.startToggleAnimation();
    }

    stopAnimation() {
        this.animation.stop();
        this.animation.visible = false;
        this.clearToggleAnimation();
    }

    enable() {
        super.enable();
        this.startAnimation();
    }

    disable() {
        super.disable();
        this.stopAnimation();
    }

    startToggleAnimation() {
        this.clearToggleAnimation();
        this.interval = window.setInterval(() => {
            if (!this.enabled) {
                return;
            }
            this.toggleAnimation();
        }, 5000)
    }

    clearToggleAnimation() {
        window.clearInterval(this.interval);
    }

    toggleAnimation() {
        if (!this.enabled) {
            this.animation.visible = false;
            return;
        }

        this.animation.visible = !this.animation.visible;
    }
}
