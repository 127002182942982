import {TextStyle} from "pixi.js";

export const BottomPanelTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 14,
    fill: '#ffffff',
    align: 'center',
    fontWeight: 'bold',
});

export const ListTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 14,
    fill: '#ffffff',
    align: 'center',
    fontWeight: 'bold',
});

export const AutoplayListTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 13.8,
    fill: '#ffffff',
    align: 'center',
    fontWeight: 'bold',
});

export const BetsListTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 25,
    fill: '#ffffff',
    align: 'center',
    fontWeight: 'bold',
});

export const LoadingTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fill: '#ffffff',
    align: 'center',
    smooth: true,
});

export const ClockTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 18,
    fill: '#ffffff',
});

export const TargetTextStyle = new TextStyle({
    "align": "center",
    fontFamily: 'Nunito',
    "dropShadowAngle": "",
    "dropShadowColor": "#fe0303",
    "dropShadowDistance": "",
    "fill": "#fafc02",
    "fontSize": 35,
    "lineJoin": "round",
    "stroke": "#fe0303",
    "strokeThickness": 5
});

export const ListLabelTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 24,
    fill: '#ffffff',
    fontWeight: 'bold',
    align: "center"
});

export const InfoLabelTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 25,
    fill: 0xffffff,
    align: 'center',
    fontWeight: 'bold',
});

export const GameProgressPointTextStyle = new TextStyle({
        fontFamily: 'Nunito',
        fontSize: 18,
        fill: 0xffffff,
        align: 'center',
        fontWeight: 'bold'
});

export const WinTextStyle = new TextStyle({
    "align": "center",
    "fontFamily": 'Nunito',
    "dropShadowAngle": "",
    "dropShadowColor": "#fe0303",
    "dropShadowDistance": "",
    "fill": "#fafc02",
    "fontSize": 46,
    "lineJoin": "round",
    "stroke": "#fe0303",
    "strokeThickness": 5,
    "fontWeight": "bold"
});

export const ButtonTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 24,
    fill: '#ffffff',
});

export const SettingsLabelTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 16,
    fill: '#ffffff',
    align: 'center',
});

export const CheckBoxTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 14,
    fill: '#ffffff',
});

export const ProgressTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 14,
    fill: '#FAFC02',
    align: 'center',
});

export const SegmentedSelectTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 16,
    fill: '#ffffff',
    align: 'center',
    fontWeight: 'bold',
});

export const SegmentedSelectTextStyleActive = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 16,
    fill: '#FAFC02',
    align: 'center',
    fontWeight: 'bold'
});

export const LobbyHeadersTextStyle = new TextStyle({fill: 0xffffff, fontSize: 18, fontWeight: 'bold', fontFamily: 'Nunito'});
export const AutoPlayButtonCounterTextStyle = new TextStyle({
    fontSize: 14,
    fill: '#FAFC02',
    align: 'center',
    fontFamily: 'Nunito'
});
export const  SelectCountryTextStyle = new TextStyle({fill: 0xffffff, fontSize: 20, fontWeight: 'bold', fontFamily: 'Nunito'});
export const  CountryLabelTextStyle = new TextStyle({fill: 0xffffff, fontSize: 16, fontFamily: 'Nunito'});
export const  LevelTextStyle = new TextStyle({fill: 0xffffff, fontSize: 20, fontWeight: 'bold', fontFamily: 'Nunito'});
export const  LevelNameTextStyle = new TextStyle({fill: 0xffffff, fontSize: 14, fontFamily: 'Nunito'});
export const  LevelMultiplierTextStyle = new TextStyle({fill: 0xffffff, fontSize: 14, fontWeight: 'bold', fontFamily: 'Nunito'});
export const PopupNameTextStyle = new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 34,
    fill: 0xffffff,
    align: 'center',
    fontWeight: 'bold'
});

export const BetTextStyle =  new TextStyle({
    fontFamily: 'Nunito',
    fontSize: 20,
    fill: 0xffffff,
    align: 'center',
});

export const RotateMessageStyle = new TextStyle({fill: 0xffffff, fontSize: 56, fontFamily: 'Nunito', align: 'center', fontWeight: 'bold'});

export const AutoplayVariantTextStyle= new TextStyle({fill: '#FAFC02', fontSize: 16, fontFamily: 'Nunito'});

export const SessionExpiredPopupTextStyle = new TextStyle({
fontFamily: 'Nunito',
    fontSize: 24,
    fill: '#ffffff',
    align: 'center',
    fontWeight: 'bold',
    wordWrap: true,
    wordWrapWidth: 600
});
