import { Container, Graphics, Sprite } from 'pixi.js';

export class ProgressLine extends Container {
    constructor(spriteTexture) {
        super();

        this.lineSprite = new Sprite(spriteTexture);
        this.progressWidth = this.lineSprite.width;
        this.progressHeight = this.lineSprite.height;
        this.addChild(this.lineSprite);

        this.mask = new Graphics();

        this.mask
            .beginFill('0xffffff')
            .drawRoundedRect(0, 0, this.progressWidth, this.progressHeight, this.height / 2)
            .endFill();

        this.addChild(this.mask);

        this.lineSprite.mask = this.mask;

        this._progress = 0;
        this.setProgress(0);
    }

    setProgress(progress) {
        if (progress < 0) progress = 0;
        if (progress > 100) progress = 100;

        this._progress = progress;

        const maskWidth = (this.progressWidth * progress) / 100;
        this.mask
            .clear()
            .beginFill('0xffffff')
            .drawRoundedRect(0, 0, maskWidth, this.progressHeight, this.height / 2)
            .endFill();
    }

    set progress(value) {
        this.setProgress(value);
    }

    get progress() {
        return this._progress;
    }
}
