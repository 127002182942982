import {Button} from "./Button";
import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {AutoPlayButtonCounterTextStyle} from "../configs/textStyles";

export class AutoplayButton extends Container {
    constructor() {
        super();

        this.button = ObjectFactory.createButton({
            texturesNames: {
                default: 'CircleButtonDefault.png',
                pressed: 'CircleButtonPressed.png',
                hover: 'CircleButtonHover.png',
                disabled: 'CircleButtonDisabled.png',
            },
            onClick: this.onClick.bind(this)
        });
        this.button.width = this.button.height = 72;
        this.addChild(this.button);

        this.startIcon = ObjectFactory.createSpriteFromSheet('AutoplayStartButtonIcon.png');
        this.startIcon.anchor.set(0.5);
        this.startIcon.x = this.button.width / 2;
        this.startIcon.y = this.button.height / 2;
        this.addChild(this.startIcon);


        this.stopIcon = ObjectFactory.createSpriteFromSheet('AutoplayStopButtonIcon.png');
        this.stopIcon.anchor.set(0.5);
        this.stopIcon.x = this.button.width / 2;
        this.stopIcon.y = this.button.height / 2 - 12;
        this.addChild(this.stopIcon);

        this.counterLabel = ObjectFactory.createText(99, AutoPlayButtonCounterTextStyle);
        this.counterLabel.anchor.set(0.5);
        this.counterLabel.x = this.button.width / 2;
        this.counterLabel.y = this.button.height / 2 + 12;
        this.addChild(this.counterLabel);

        this.state = 'start';
        this.toStartButton();
    }

    disable() {
        this.button.disable();
        this.startIcon.tint = '#3b3b3b';
        this.startIcon.alpha = 0.5;
    }

    enable() {
        this.button.enable();
        this.startIcon.tint = '#FFFFFF';
        this.startIcon.alpha = 1;
    }

    onClick() {
        if (this.state === 'start') {
            this.onStart();
        } else {
            this.onStop();
        }
    }

    onStart() {
        this.emit('onClickStart');
    }

    onStop() {
        this.emit('onClickStop');
    }

    toStopButton() {
        this.state = 'stop';
        this.stopIcon.visible = true;
        this.counterLabel.visible = true;
        this.startIcon.visible = false;
    }

    toStartButton() {
        this.state = 'start';
        this.stopIcon.visible = false;
        this.counterLabel.visible = false;
        this.startIcon.visible = true;
    }

    setCounter(value) {
        this.counterLabel.text = value;
    }
}


