import {BasePopup} from "./BasePopup";
import {SegmentedSelect} from "../objects/SegmentedSelect";
import {ObjectFactory} from "../ObjectFactory";
import {ProgressTextStyle, SettingsLabelTextStyle} from "../configs/textStyles";
import {Container} from "pixi.js";

const AnimationSpeedByIndex = [0.8, 1, 2];

export class Settings extends BasePopup {
    constructor(game, onClose = () => {} ) {
        super(game, onClose);

        this.currentAnimationSpeed = this.game.settings.gamePlayAnimationSpeed;

        this.animationSpeedLabel = this.addChild(ObjectFactory.createText('Animation speed', SettingsLabelTextStyle));
        this.animationSpeedRadioGroup = this.addChild(new SegmentedSelect({currentValue: this.getIndexByAnimationSpeed(), onSelect: this.changeAnimationSpeed.bind(this)}));
        this.animationSpeedRadioGroup.scale.set(0.8);

        this.animationSpeedLabel.x = this.bg.x + 50;
        this.animationSpeedLabel.y = this.bg.y + 200;
        this.animationSpeedRadioGroup.x = this.animationSpeedLabel.x;
        this.animationSpeedRadioGroup.y = this.animationSpeedLabel.y + this.animationSpeedLabel.height + 10;

        this.animationsText = this.addChild(ObjectFactory.createText('Animations', SettingsLabelTextStyle));
        this.animationsText.x = 642;
        this.animationsText.y = this.animationSpeedLabel.y;

        this.kickAnimationCheckBox = this.addChild(ObjectFactory.createCheckBox('Kick', this.game.settings.kickAnimation));
        this.kickAnimationCheckBox.x = this.animationsText.x - 6;
        this.kickAnimationCheckBox.y = this.animationSpeedRadioGroup.y;

        this.winAnimationCheckBox = this.addChild(ObjectFactory.createCheckBox('Win', this.game.settings.winAnimation));
        this.winAnimationCheckBox.x = this.kickAnimationCheckBox.x + this.kickAnimationCheckBox.width + 20;
        this.winAnimationCheckBox.y =  this.kickAnimationCheckBox.y;

        this.musicSlider = this.addChild(new SliderGroup('Music volume',  this.game.soundPlayer.musicVolume * 100, this.onChangeMusicVolume.bind(this)));
        this.musicSlider.x = this.bg.x + 60;
        this.musicSlider.y = this.bg.y + 100;

        this.soundSlider = this.addChild(new SliderGroup('Sound volume', this.game.soundPlayer.swxVolume * 100, this.onChangeSFXVolume.bind(this)));
        this.soundSlider.x = this.animationsText.x;
        this.soundSlider.y = this.bg.y + 100;

        this.kickAnimationCheckBox.on('change', this.changeKickAnimation.bind(this));
        this.winAnimationCheckBox.on('change', this.changeWinAnimation.bind(this));
    }

    onChangeSFXVolume(value) {
        this.game.soundPlayer.setSFXVolume(value / 100);
    }

    onChangeMusicVolume(value) {
        this.game.soundPlayer.setMusicVolume(value / 100);
    }

    changeKickAnimation(state) {
        this.game.settings.kickAnimation = state;
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    changeWinAnimation(state) {
        this.game.settings.winAnimation = state;
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    changeAnimationSpeed(index) {
        this.currentAnimationSpeed = AnimationSpeedByIndex[index];
        this.game.settings.gamePlayAnimationSpeed = this.currentAnimationSpeed;
        this.game.scene.setAnimationSpeed(this.currentAnimationSpeed);
        this.game.soundPlayer.playSWX('buttonClickSound');
    }

    getIndexByAnimationSpeed() {
        return AnimationSpeedByIndex.indexOf(this.currentAnimationSpeed);
    }

    close() {
        super.close();
        this.game.fms.goToPrevious();
    }
}

class SliderGroup extends Container {
    constructor(label, value, onChange = () => {}) {
        super();

        this.label = ObjectFactory.createText(label, SettingsLabelTextStyle);
        this.addChild(this.label);

        this.progressText = ObjectFactory.createText(`${value}%`, ProgressTextStyle);

        this.addChild(this.progressText);

        this.slider = ObjectFactory.createSlider();
        this.slider.setProgress(value);
        this.slider.on('change', this.onChange.bind(this));
        this.slider.on('change', onChange);

        this.addChild(this.slider);

        this.label.x = 0;
        this.label.y = 0;
        this.slider.x = 0;
        this.slider.y = this.label.y + this.label.height + 20;
        this.progressText.anchor.x = 1
        this.progressText.x = this.slider.x + this.slider.width - 16;
        this.progressText.y = this.label.y + 4;
    }

    onChange(value) {
        this.progressText.text = `${value}%`;
    }
}
