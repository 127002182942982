import {BasePopup} from "./BasePopup";
import {ObjectFactory} from "../ObjectFactory";
import {SessionExpiredPopupTextStyle} from "../configs/textStyles";

const TextMessage = `Not Enough Funds!`;

export class NotEnoughFundsPopup extends BasePopup {
    constructor(game, name, onClose = () => {
    }) {
        super(game, name, onClose);

        this.label.text = '';

        this.depositLink = this.game.config.depositLink;
        this.bg.texture = ObjectFactory.getTextureFromSpriteSheet('NotEnoughFundsPopupBG.png');

        this.message = ObjectFactory.createText(TextMessage, SessionExpiredPopupTextStyle);
        this.message.x = this.bg.x + this.bg.width / 2;
        this.message.y = this.bg.y + this.bg.height * 0.7;
        this.message.anchor.set(0.5);
        this.addChild(this.message);

        if (this.depositLink) {
            this.button = this.game.objectFactory.createButton({
                default: 'ButtonNormal.png',
                hover: 'ButtonHover.png',
                pressed: 'ButtonSelected.png',
                disabled: 'ButtonDisabled.png',
            }, () => this.deposit(), 'Deposit Now!', 0.6);
            this.button.x = this.bg.x + this.bg.width / 2 - this.button.width / 2;

            this.addChild(this.button);

            this.message.y = this.bg.y + this.bg.height * 0.6;
            this.button.y = this.bg.y + this.bg.height * 0.75;
        }
    }

    deposit(){
        if (this.depositLink) {
            this.game.postMessage(`goToHomePage#${this.depositLink}`);
        }
    }

    close() {
        super.close();
        this.game.fms.goToPrevious();
    }
}
