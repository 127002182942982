import {GameState} from "../GameState";

export class AutoplaySettings extends GameState {
    async onEnter() {
        super.onEnter();
        this.game.popupManager.showPopup('Autoplay');
        this.game.scene.showBlur();
    }

    async onExit() {
        super.onExit();

        this.game.scene.hideBlur();
        this.game.popupManager.hidePopup('Autoplay');

        if (this.game.settings.autoBet) {
            this.game.scene.autoplayButton.enable();
            this.game.scene.autoplayButton.toStopButton();
            this.game.scene.betsLis.setValues([this.game.settings.autoplay.bet], 0);
        }
    }
}
