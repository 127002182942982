export class ErrorsHandler {
    constructor(game) {
        this.game = game;
        window.onerror = (message, source, lineno, colno, error) => {
           this.showError(error);
        };

        window.addEventListener('unhandledrejection',  (event)  => {
            this.showError(event.reason);
        });

        this.errorsPopupByErrors = {
            'SessionExpiredError': 'SessionExpiredPopup',
            'NotEnoughFunds': 'NotEnoughFundsPopup',
            'ErrorMessageError': 'ErrorMessagePopup',
        };
    }

    showError(error) {
        const errorPopup = this.errorsPopupByErrors[error.name] || 'SessionExpiredPopup';

        if (errorPopup) {
            this.game.popupManager.showPopup(errorPopup, error);
            this.game.fms.goTo('errorsState');
        }
    }
}
