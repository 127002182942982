import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {CountryLabelTextStyle, SelectCountryTextStyle} from "../configs/textStyles";
import {sound} from "@pixi/sound";

export class CountrySlider extends Container {
    constructor(countries) {
        super();
        this.label = ObjectFactory.createText('Select a country', SelectCountryTextStyle);
        this.label.anchor.set(0.5);

        this.addChild(this.label);

        this.carousel = ObjectFactory.createCarousel({
            elements: countries.map(name => new Country(name)),
        });
        this.carousel.on('selected', this.onSelect.bind(this));
        this.addChild(this.carousel);

        this.bg =  this.carousel.addChildAt(ObjectFactory.createSprite('CountrySelected.png'), 0);
        this.bg.anchor.set(0.5);

        this.label.y = - (this.carousel.height / 2 + 20);
    }

    getCurrentIndex() {
        return this.carousel.getCurrentIndex();
    }

    onSelect(index) {
        this.emit('selected', index);
        sound.play('swipeSliderSound');
    }
}

class Country extends Container {
    constructor(name) {
        super();
        this.flag = ObjectFactory.createSpriteFromSheet(`${name}.png`);
        this.flag.anchor.set(0.5, 0.5);
        this.flag.y = -12;
        this.addChild(this.flag);

        this.label = ObjectFactory.createText(name, CountryLabelTextStyle);
        this.label.anchor.set(0.5);
        this.label.y = this.flag.height * 0.3;
        this.addChild(this.label);
    }
}
