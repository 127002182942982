import { Container, AnimatedSprite } from 'pixi.js';
import ValueTween from "../ValueTween";

export class LightAnimation extends Container {
    constructor(animationFrames) {
        super();
        this.animations = [];
        this.animationFrames = animationFrames;
        this.createAnimations();
        this.startRandomAnimations();
    }

    createAnimations() {

        const postions = [
            {x: -43, y: 173},
            { x: 176, y: 194},
            { x: 422, y: 188},
            { x: 642, y: 185 },
            { x: 888, y: 193},
            { x: 1105, y: 173}
        ];
        for (let i = 0; i < postions.length; i++) {
            const animation = AnimatedSprite.fromFrames(this.animationFrames);
            animation.animationSpeed = 0.5;
            animation.loop = false;
            animation.alpha = 0;
            animation.x = postions[i].x;
            animation.y = postions[i].y;
            this.addChild(animation);
            this.animations.push(animation);
        }
    }

    startRandomAnimations() {
        this.animations.forEach(animation => {
            this.scheduleAnimation(animation);
        });
    }

    scheduleAnimation(animation) {
        const delayTime = Math.random() * 3000 + 2000;
        setTimeout(async () => {
            await this.animate(animation);
            this.scheduleAnimation(animation);
        }, delayTime);
    }

    async animate(animation) {
        await ValueTween.to(animation, { key: 'alpha', to: 1 }, 500, 'easeInQuad');
        animation.gotoAndPlay(0);
        await new Promise(resolve => animation.onComplete = resolve);
        await ValueTween.to(animation, { key: 'alpha', to: 0 }, 500, 'easeOutQuad');
    }
}
