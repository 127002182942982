import {GameState} from "../GameState";

export class WinAllRounds extends GameState {
    async onEnter() {
        this.game.fms.goTo('collectWinning');
    }

    async onExit() {
    }
}
