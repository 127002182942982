import {Container} from 'pixi.js';
import {ClockTextStyle} from "../configs/textStyles";
import {ObjectFactory} from "../ObjectFactory";

export class Clock extends Container {
    constructor() {
        super();

        this.timeText = ObjectFactory.createText('', ClockTextStyle);
        this.timeText.alpha = 0.5;
        this.addChild(this.timeText);

        this.updateTime();
        this.interval = setInterval(() => this.updateTime(), 1000);
    }

    updateTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        this.timeText.text = `${hours}:${minutes}`;
    }

    destroy(options) {
        clearInterval(this.interval);
        super.destroy(options);
    }
}
