import {GameState} from "../GameState";

export class WinRound extends GameState {
    async onEnter() {

        await this.game.scene.goToNextLevel(this.game.server.roundTarget, `x${this.game.server.currentMultiplier}`);

        this.game.scene.setWin(this.game.server.roundWin);

        if (this.game.server.isLastRound()) {
            this.game.fms.goTo('winAllRounds');
            return;
        }

        this.game.fms.goTo('waitKick');
    }

    async onExit() {
    }
}
