import {Application, utils} from "pixi.js";
import {UrlParser} from "./URLParser";
import {OfflineMode} from "./OfflineMode";
import {OnlineMode} from "./OnlineMode";
import {FMS} from "./FMS";
import {ObjectFactory} from "./ObjectFactory";
import {GameSceneManager} from "./GameSceneManager";
import {PopupManager} from "./PopupManager";
import {SoundPlayer} from "./SoundPlayer";
import {RotateScreen} from "./RotateScreen";
import {ErrorsHandler} from "./services/ErrorsHandler";

const WIDTH  = 1220;
const HEIGHT = 820;

export class GameApplication extends Application {
    constructor(options, gameOptions) {
        super(options);

        this.gameContainer = document.getElementById('game-container');

        this.objectFactory = new ObjectFactory();
        this.scenes = new GameSceneManager(this, gameOptions.scenes);
        this.server = gameOptions.offline ? new OfflineMode({game: this, balance: gameOptions.balance, bets: gameOptions.bets, ...gameOptions}) : new OnlineMode({game: this, balance: gameOptions.balance, session_uuid: gameOptions.session_uuid, bets: gameOptions.bets, ...gameOptions});
        this.fms = new FMS(this, gameOptions.states);
        this.popupManager = new PopupManager(gameOptions.popups, this);
        this.soundPlayer = new SoundPlayer();
        this.errorsHandler = new ErrorsHandler(this);
        this.rotateScreen = null;

       this.config = gameOptions;

       this.stage.addChild(this.scenes, this.popupManager);

       this.settings = {
           gamePlayAnimationSpeed: 1,
           kickAnimation: true,
           winAnimation: true,
           autoBet: false
       };
    }

    async init(){
        this.gameContainer.appendChild(this.view);

        this.fms.goTo('load-screen');

        if (utils.isMobile.any) {
            this.setResizeType('contain');
        } else {
            this.setResizeType('');
        }
        this.showRotateSceneIfNeeded();

        window.addEventListener('resize', this.resizeByType.bind(this));
        window.addEventListener('orientationchange', this.onChangeOrientation.bind(this));
        this.resizeByType();
    }
    containResize( ) {
        this.resizeToTarget(window.innerWidth, window.innerHeight);
    }

    resizeToTarget(targetWidth, targetHeight) {
        let newWidth = targetWidth;
        let newHeight = targetHeight;

        if (targetHeight < targetWidth) {
            newWidth = (targetHeight / HEIGHT) * WIDTH;
        } else {
            newHeight = (targetWidth/ WIDTH) * HEIGHT;
        }

        this.setSizeContainer(newWidth, newHeight);
    }

    setSizeContainer(width, height) {
        this.gameContainer.style.width = width + 'px';
        this.gameContainer.style.height = height + 'px';
    }

    coverScreen() {
        const containerWidth = window.innerWidth;
        const containerHeight = window.innerHeight;
        const aspectRatio = WIDTH / HEIGHT;

        let newWidth, newHeight;

        if (containerWidth / containerHeight >= aspectRatio) {
            newHeight = containerHeight;
            newWidth = newHeight * aspectRatio;
        } else {
            newWidth = containerWidth;
            newHeight = newWidth / aspectRatio;
        }

        this.setSizeContainer(containerWidth, containerHeight);
        this.renderer.resize(containerWidth, containerHeight);

        const scale = Math.min(containerWidth / WIDTH, containerHeight / HEIGHT);

        this.stage.scale.set(scale);
        this.stage.x = containerWidth / 2 - WIDTH * scale / 2;
        this.stage.y =  containerHeight / 2 - HEIGHT * scale / 2;
    }

    inCenter() {
        this.setSizeContainer(WIDTH, HEIGHT);
        this.renderer.resize(WIDTH, HEIGHT);

        this.stage.scale.set(1);
        this.stage.x = 0;
        this.stage.y = 0;
    }

    setResizeType(type) {
        this.resizeType = type;
        this.renderer.resize(WIDTH, HEIGHT);
        this.stage.scale.set(1);
        this.stage.x = 0;
        this.stage.y = 0;
        this.resizeByType();
    }

    resizeByType() {
        switch (this.resizeType) {
            case 'cover':
                this.coverScreen();
                break;
            case 'contain':
                this.containResize();
                break;
            default:
                this.inCenter();
        }
    }

    onChangeOrientation() {
        this.showRotateSceneIfNeeded();
    }

    isPortrait() {
        return window.screen.orientation.type.includes('portrait');
    }

    showRotateSceneIfNeeded() {
        if (utils.isMobile.any && this.rotateScreen) {
            if (this.isPortrait()) {
                this.rotateScreen.visible = true;
                this.scenes.visible = false;
                this.popupManager.visible = false;
            } else {
                this.rotateScreen.visible = false;
                this.scenes.visible = true;
                this.popupManager.visible = true;
            }
        }
    }

    addRotateScreen() {
        if (!this.rotateScreen) {
            this.rotateScreen = new RotateScreen(this);
            this.rotateScreen.visible = false;
            this.stage.addChild(this.rotateScreen);
        }
    }

    postMessage(message) {
        window.parent.postMessage(message, '*');
        console.log(message);
    }
}
