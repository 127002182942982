import {Assets, Container, Sprite, Text} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {BottomPanelTextStyle, InfoLabelTextStyle} from "../configs/textStyles";

export class InfoLabel extends Container {
    constructor(text, value) {
        super();

        this.bg = Sprite.from(Assets.cache.get('assets/all-0.json').textures['InfoLableBackground.png']);
        this.bg.anchor.set(0.5);

        this.addChild(this.bg);

        this.text = ObjectFactory.createText(text, {...BottomPanelTextStyle,  maxWidth: this.bg.width - 10});
        this.text.y = - 37;
        this.text.anchor.set(0.5);
        this.addChild(this.text);

        this.value =  ObjectFactory.createText(value, InfoLabelTextStyle);
        this.value.anchor.set(0.5);
        this.value.y = -2;
        this.addChild(this.value);

        this.setValue(value);
    }

    setValue(value) {
        this.value.text = value;

        if (this.value.width > this.bg.width - 20) {
            this.value.width = this.bg.width - 20;
        }
    }
}
