import { Container, Graphics, Sprite, Text, TextStyle } from 'pixi.js';
import {ProgressLine} from './ProgressLine.js';

export class Slider extends Container {
    constructor(lineTexture, knobTexture, bgTexture) {
        super();

        this.background = Sprite.from(bgTexture);
        this.background.anchor.y = 0.5;
        this.addChild(this.background);

        this.progressLine = new ProgressLine(lineTexture);
        this.addChild(this.progressLine);

        this.background.x = this.progressLine.width / 2 - 2;
        this.background.y = this.progressLine.height / 2;

        this.knob = new Sprite(knobTexture);
        this.knob.anchor.set(0.5);
        this.knob.y = this.progressLine.height / 2;
        this.knob.interactive = true;
        this.knob.buttonMode = true;
        this.knob.cursor = 'pointer';
        this.addChild(this.knob);

        this.knob.on('pointerdown', this.onDragStart.bind(this));
        this.on('pointerup', this.onDragEnd.bind(this));
        this.on('pointerupoutside', this.onDragEnd.bind(this));
        this.on('pointermove', this.onDragMove.bind(this));
        this.interactive = true;

        this.setProgress(0);
    }

    setProgress(progress) {
        this.progressLine.progress = progress;
        this.updateKnobPosition();
    }

    updateKnobPosition() {
        this.knob.x = (this.progressLine.progressWidth * this.progressLine.progress) / 100;
    }

    onDragStart(event) {
        this.dragging = true;
        this.data = event.data;
    }

    onDragEnd() {
        this.dragging = false;
        this.data = null;
    }

    onDragMove() {
        if (this.dragging) {
            const newPosition = this.data.getLocalPosition(this.progressLine);
            let progress = Math.round((newPosition.x / this.progressLine.progressWidth) * 100);

            if (progress < 0) {
                progress = 0;
            } else if (progress > 100) {
                progress = 100;
            }

            this.setProgress(progress);
            this.emit('change', progress);
        }
    }
}
