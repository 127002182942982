import {GameState} from "../GameState";
import {utils} from "pixi.js";

export class  SelectLevel extends GameState {
    async onEnter() {
        super.onEnter();

        this.game.setResizeType('cover');
        this.game.scene =  this.game.scenes.showScene('lobby');
        this.game.soundPlayer.playMainMusic();
    }

    async onExit() {
        super.onExit();
        if (utils.isMobile.any) {
            this.game.setResizeType('contain');
        } else {
            this.game.setResizeType('');
        }

        this.game.scene =  this.game.scenes.showScene('gamePlay');
    }
}
