import { Container, Sprite, Text, TextStyle } from 'pixi.js';
import {ObjectFactory} from "../ObjectFactory";
import {CheckBoxTextStyle} from "../configs/textStyles";

class CheckBox extends Container {
    constructor(textures, text = '', initialState = false) {
        super();

        this.textures = textures;
        this.state = initialState;

        this.sprite = new Sprite(this.state ? textures.checked : textures.unchecked);
        this.addChild(this.sprite);

        if (text) {
            this.text = ObjectFactory.createText(text, CheckBoxTextStyle)
            this.text.anchor.set(0.5, 0.5);
            this.text.x = this.sprite.width + 10 + this.text.width / 2;
            this.text.y = this.sprite.height / 2;
            this.addChild(this.text);
        }

        this.sprite.interactive = true;
        this.sprite.buttonMode = true;
        this.sprite.cursor = 'pointer';
        this.sprite.on('pointerdown', this.toggle.bind(this));
    }

    toggle() {
        this.state = !this.state;
        this.sprite.texture = this.state ? this.textures.checked : this.textures.unchecked;
        this.emit('change', this.state);
    }

    setChecked(state) {
        this.state = state;
        this.sprite.texture = this.state ? this.textures.checked : this.textures.unchecked;
    }

    isChecked() {
        return this.state;
    }
}

export default CheckBox;
