import { Container, Sprite, Texture, Text } from 'pixi.js';
import { ProgressLine } from './ProgressLine.js';
import {LoadingTextStyle} from "../configs/textStyles";
import {ObjectFactory} from "../ObjectFactory";

export class GameLoadingProgressBar extends Container {
    constructor(backgroundTexture, lineTexture) {
        super();

        this.loadingText = ObjectFactory.createText('Loading...', LoadingTextStyle);
        this.loadingText.anchor.set(0.5);
        this.loadingText.x = backgroundTexture.width / 2;
        this.loadingText.y = - 12;
        this.loadingText.resolution = 2;
        this.addChild(this.loadingText);

        this.background = new Sprite(backgroundTexture);
        this.addChild(this.background);

        this.progressLine = new ProgressLine(lineTexture);
        this.addChild(this.progressLine);

        this.progressLine.x = (this.background.width - this.progressLine.progressWidth) / 2 - 1;
        this.progressLine.y = (this.background.height - this.progressLine.progressHeight) / 2 - 2;
    }

    setProgress(progress) {
        this.progressLine.setProgress(progress);
    }
}
