import {SessionExpiredPopup} from "./SessionExpiredPopup";

export class ErrorMessagePopup extends SessionExpiredPopup {
    constructor(game, name, onClose = () => {},) {
        super(game, name, onClose);

        this.message.text = '';

        if (this.homeLink) {
            this.message.y = this.bg.y + this.bg.height * 0.5;
        }
    }

    open(options) {
        super.open(options);
        this.message.text = options.message;
    }
}

