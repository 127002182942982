import {AnimatedSprite, Assets, Container, Sprite, Text} from "pixi.js";
import {ProgressLine} from "./ProgressLine";
import {ValueTween} from "../ValueTween";
import {GameProgressPointTextStyle} from "../configs/textStyles";
import {ObjectFactory} from "../ObjectFactory";

export class GameProgressBar extends Container {
    constructor() {
        super();

        this.progress = 0;
        this.maxProgress = 5;
        this.timeScale = 1;

        this.background = Sprite.from(Assets.cache.get('assets/all-0.json').textures['gameProgressLine.png']);
        this.background.anchor.set(0, 0.5);
        this.addChild(this.background);

        const progressLinesPositons = [
            {x: 35, y: 0},
            {x: 211, y: 0},
            {x: 387, y: 0},
            {x: 562, y: 0}
        ];

        this.progressLines = progressLinesPositons.map((position) => {
            const container = new ProgressLine(Assets.cache.get('assets/all-0.json').textures['GameProgressBarLine.png']);
            container.x = position.x;
            container.y = -6;
            container.setProgress(0);

            this.addChild(container);

            return container;
        });

        const progressPointPositons = [
            {x: 35, y: 0},
            {x: 211, y: 0},
            {x: 387, y: 0},
            {x: 562, y: 0},
            {x: 739, y: 0},
        ];

        this.progressPoints = progressPointPositons.map((position) => {
            const container = new GameProgressPoint();
            container.x = position.x;
            container.y = position.y;

            this.addChild(container);

            return container;
        });
    }

    async first() {
        await this.progressPoints[0].showBoll();
    }

    async toNextStep(value, notAnimate = false) {
        if (this.progress >= this.maxProgress) {
            return;
        }

        if (notAnimate) {
            this.progressPoints[this.progress].showGoldBoll(value, notAnimate);
        } else {
            await this.progressPoints[this.progress].showGoldBoll(value, notAnimate);
        }

        this.progress++;

        if (this.progress >= this.maxProgress) {
            return;
        }

        if (!notAnimate) {
            await ValueTween.to(this.progressLines[this.progress - 1], {key: 'progress', to: 100}, 500 / this.timeScale)
            await this.progressPoints[this.progress].showBoll();
        } else {
            this.progressLines[this.progress - 1].progress = 100;
            this.progressPoints[this.progress].showBoll(notAnimate);
        }
    }

    reset() {
        this.progress = 0;
        this.progressPoints.forEach((point) => {
            point.reset();
        });

        this.progressLines.forEach((line) => {
            line.setProgress(0);
        });
    }

    getCurrentPointPosition() {
        const point = this.progressPoints[this.progress];
        const position = point.getGlobalPosition();

        return {x: position.x, y: position.y};
    }

    restore(rounds) {
        rounds.forEach(async (round, roundNumber) => {
            await this.toNextStep(`x${round.multiplier}`, true);
        });
    }
}

class GameProgressPoint extends Container {
    constructor() {
        super();

        this.bg = Sprite.from(Assets.cache.get('assets/all-0.json').textures['GameProgressPointBackgorund.png']);
        this.bg.anchor.set(0.5);

        this.addChild(this.bg);

        this.pointAnimation = AnimatedSprite.fromFrames(Assets.cache.get('assets/sprite-animations-0.json').data.animations['3_2/3_2']);
        this.pointAnimation.anchor.set(0.5);
        this.pointAnimation.loop = false;
        this.pointAnimation.x = -1;
        this.pointAnimation.visible = false;
        this.addChild(this.pointAnimation);

        this.value = ObjectFactory.createText( '',GameProgressPointTextStyle);
        this.value.anchor.set(0.5);
        this.value.y = 44;

        this.addChild(this.value);
    }

    reset() {
        this.bg.visible = true;
        this.pointAnimation.currentFrame = 0;
        this.pointAnimation.visible = false;
        this.value.text = '';
    }

    async showBoll() {
        this.bg.visible = false;
        this.pointAnimation.visible = true;
        this.value.text = '';
    }

    async showGoldBoll(text, notAnimate = false) {
        this.bg.visible = false;
        this.pointAnimation.visible = true;
        this.value.text = text;

        if (notAnimate) {
            this.pointAnimation.gotoAndStop(this.pointAnimation.totalFrames - 1);
            return;
        }

        this.pointAnimation.play();

        return new Promise((resolve) => {
            this.pointAnimation.onComplete = () => {
                resolve();
            };
        });
    }

    set animationSpeed(value) {
        this.pointAnimation.animationSpeed = value;
        this.timeScale = value;
    }
}
