import {Container} from "pixi.js";
import {Target} from "./Target";
import ValueTween from "../ValueTween";

export class GateTargets extends Container {
    constructor(targetHandler) {
        super();

        this.timeScale = 1;

        this.targets = [];
        this.targetsPositions = [
            {x: 337, y: 531},
            {x: 347, y: 362},
            {x: 572, y: 362},
            {x: 795, y: 362},
            {x: 804, y: 531},
        ];

        this.targets = this.targetsPositions.map((position, index) => {
            const target = new Target();
            target.x = position.x;
            target.y = position.y;
            target.id = index;
            target.on('pointertap', () =>targetHandler(target.id));

            this.addChild(target);

            return target;
        });
    }

    enable() {
        this.targets.forEach((target) => target.enable());
    }

    disable() {
        this.targets.forEach((target) => target.disable());
    }


    async showAll() {
        for (let i = 0; i < this.targets.length; i++) {
            this.targets[i].visible = true;
            this.targets[i].aim.visible = false;
            this.targets[i].text.visible = false;
            this.targets[i].animation.visible = true;
            this.targets[i].enable();
        }
    }

    reset() {
        this.targets.forEach((target) => {
            target.visible = true;
            target.aim.visible = false;
        });
    }

    async showWin(targetNumber, multiplier) {
        for (let i = 0; i < this.targets.length; i++) {
            this.targets[i].disable();
            this.targets[i].visible = false;
        }

        this.targets[targetNumber].visible = true;
        await this.targets[targetNumber].showWin(`x${multiplier}`);
    }

    async hideAll() {
        for (let i = 0; i < this.targets.length; i++) {
            this.targets[i].disable();
            this.targets[i].visible = false;
        }
    }

    async playAllTargetsAnimation() {
        for (let i = 0; i < this.targets.length; i++) {
            this.targets[i].playAnimation();
        }
    }

    async selectTarget(targetNumber) {
        this.targets[targetNumber].aim.visible = true;

        for (let i = 0; i < this.targets.length; i++) {
            this.targets[i].disable();

            if (i !== targetNumber) {
                this.targets[i].visible = false;
            }
        }
    }

    async winToPosition(targetNumber, multiplier, position) {
        const target = this.targets[targetNumber];
        // move current target to position
        await Promise.all([
            ValueTween.to(target, {key: 'x', to: position.x - (target.width * 0.5) / 2}, 500 / this.timeScale),
            ValueTween.to(target, {key: 'y', to: position.y}, 500 / this.timeScale),
            ValueTween.to(target, {key: 'scale', to: 0.5, from: 1, setter: value => {
                target.scale.set(value);
                }}, 500 / this.timeScale),
        ]).then(() => {
            target.visible = false;
            target.x = this.targetsPositions[targetNumber].x;
            target.y = this.targetsPositions[targetNumber].y;
            target.scale.set(1);
        });
    }

    set animationSpeed(value) {
        this.timeScale = value;
        this.targets.forEach((target) => target.animationSpeed = value);
    }

    disableInteractive() {
        this.targets.forEach( t => t.disableInteractive());
    }

    enableInteractive() {
        this.targets.forEach( t => t.enableInteractive());
    }

    getTargetGlobalPosition(targetNumber) {
        const globalPost = this.targets[targetNumber].getGlobalPosition();

        return {
            x: globalPost.x + this.targets[targetNumber].width / 2,
            y: globalPost.y + this.targets[targetNumber].height / 2
        }
    }
}
