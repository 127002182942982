import { Container, Sprite } from 'pixi.js';
import { Button } from './Button.js';
import ValueTween from "../ValueTween";

const time = 500;

export class CarouselSlider extends Container {
    constructor(elements, buttonTextures, elementWidth, elementHeight) {
        super();

        this.elements = elements;
        this.elementWidth = elementWidth || elements[0].width;
        this.elementHeight = elementHeight || elements[0].height;
        this.isAnimating = false;

        this.elements.forEach(element =>{
            this.addChild(element)
        });

        this.createButtons(buttonTextures);
        this.updateElements();
    }

    createButtons(buttonTextures) {
        this.leftButton = new Button({ textures: buttonTextures, onClick: this.moveLeft.bind(this) });
        const midIndex = Math.floor(this.elements.length / 2);
        this.leftButton.x = - ((this.elementWidth * midIndex) + (this.elementWidth));
        this.leftButton.y = - this.leftButton.height / 2;
        this.addChild(this.leftButton);

        this.rightButton = new Button({ textures: buttonTextures, onClick: this.moveRight.bind(this) });
        this.rightButton.x =  ((this.elementWidth * midIndex) + (this.elementWidth));
        this.rightButton.y = - this.rightButton.height / 2;
        this.rightButton.scale.x = -1;
        this.addChild(this.rightButton);
    }

    updateElements() {
        const midIndex = Math.floor(this.elements.length / 2);
        for (let i = 0; i < this.elements.length; i++) {
            const element = this.elements[i];
            const positionIndex = (i + this.elements.length) % this.elements.length;
            const offset = positionIndex - midIndex;

            element.x = offset * this.elementWidth;
        }
    }

    moveLeft() {
        if (!this.isAnimating) {
            this.animateElements(-1);
        }
    }

    moveRight() {
        if (!this.isAnimating) {
            this.animateElements(1);
        }
    }

    animateElements(direction) {
        this.isAnimating = true;
        const midIndex = Math.floor(this.elements.length / 2);

        let animationsCompleted = 0;
        const checkAnimationComplete = () => {
            animationsCompleted++;
            if (animationsCompleted === this.elements.length) {
                this.isAnimating = false;
                this.emit('selected', this.getCurrentIndex());
            }
        };

        for (let i = 0; i < this.elements.length; i++) {
            const element = this.elements[i];
            const offset = this.elementWidth * direction;
            const targetX = element.x +offset;
            const isLeftBounds = targetX < -this.elementWidth * midIndex;
            const isRightBounds = targetX > this.elementWidth * midIndex;

            if (isLeftBounds || isRightBounds) {
                const nexX = isLeftBounds ? this.rightBounds() : this.leftBounds();

                Promise.resolve().then(() => {
                    return ValueTween.to(element, {
                        key: 'x',
                        to: element.x + (offset / 2),
                        easing: 'easeInOutQuad',
                    }, time / 2)
                }).then(() => {
                    element.x = nexX;

                   return  ValueTween.to(element, {
                        key: 'x',
                        to: nexX + offset / 2,
                        easing: 'easeInOutQuad',
                    }, time / 2)
                }).then(() => checkAnimationComplete());
            } else {
                ValueTween.to(element, {
                    key: 'x',
                    to: targetX,
                    easing: 'easeInOutQuad',
                }, time).then(() => checkAnimationComplete());
            }
        }
    }

    rightBounds() {
        return this.elements.length * this.elementWidth / 2;
    }

    leftBounds() {
        return -this.elements.length * this.elementWidth / 2;
    }

    getCurrentIndex() {
        return this.elements.findIndex(element => element.x === 0);
    }
}
