import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {PopupNameTextStyle} from "../configs/textStyles";

export class BasePopup extends Container {
    constructor(game, name, onClose = () => {}) {
        super();

        this.game = game;
        this.name = name;
        this.onClose = onClose;

        this.bg = this.game.objectFactory.createSpriteFromSheet('popupbg.png');
        this.bg.x = this.game.config.screenSize.width / 2 - this.bg.width / 2;
        this.bg.y = this.game.config.screenSize.height / 2 - this.bg.height / 2;
        this.addChild(this.bg);

        this.label = ObjectFactory.createText(this.name, PopupNameTextStyle);
        this.label.x = this.bg.x + this.bg.width / 2;
        this.label.y = this.bg.y + 60;
        this.label.anchor.set(0.5);
        this.addChild(this.label);

        this.closeButton = this.game.objectFactory.createButton({
            default: 'ExitButtonDefault.png',
            hover: 'ExitButtonHover.png',
            pressed: 'ExitButtonPressed.png',
            disabled: 'ExitButtonDisabled.png',
        }, () => {
            this.close() ;
            this.game.soundPlayer.playSWX('buttonClickSound');
        });
        this.closeButton.x = this.bg.x + this.bg.width - this.closeButton.width - 20;
        this.closeButton.y = this.bg.y + 20;
        this.addChild(this.closeButton);

    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    open(options) {

    }

    close() {
        this.hide();
        this.onClose();
    }
}
