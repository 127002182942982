import { Container, Graphics, Sprite, AnimatedSprite, Texture } from 'pixi.js';
import { ValueTween } from '../ValueTween.js';
import {Timer} from "../Timer";

export class LoseAnimation extends Container {
    constructor(loseAnimationFrames, screenSize, bgTexture) {
        super();

        this.background = Sprite.from(bgTexture);
        this.background.anchor.set(0.5);
        this.background.x = screenSize.width / 2;
        this.background.y = screenSize.height / 2;
        this.background.alpha = 0;
        this.addChild(this.background);

        this.animation = AnimatedSprite.fromFrames(loseAnimationFrames);
        this.animation.anchor.set(0.5);
        this.animation.x = screenSize.width / 2;
        this.animation.y = screenSize.height / 2;
        this.animation.visible = false;
        this.animation.loop = false;
        this.addChild(this.animation);
        this.timeScale = 1;
    }

    async play() {
        await ValueTween.to(this.background, { key: 'alpha', to: 1 }, 250 / this.timeScale);

        this.animation.visible = true;
        this.animation.currentFrame = 0;
        this.animation.play();

        await new Promise(resolve => this.animation.onComplete = resolve);

        await Timer.wait(500 / this.timeScale);
        await ValueTween.to(this, { key: 'alpha', to: 0 }, 500 / this.timeScale);

        this.animation.stop();
        this.alpha = 1;
        this.animation.visible = false;
        this.background.alpha = 0;
    }

    set animationSpeed(value) {
        this.animation.animationSpeed = value;
        this.timeScale = value;
    }
}
