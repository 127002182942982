import {sound} from "@pixi/sound";

export class SoundPlayer {
    constructor() {
        this._swxVolume = localStorage.getItem('swxVolume') ? Number(localStorage.getItem('swxVolume')) : 1;
        this._musicVolume = localStorage.getItem('musicVolume') ? Number(localStorage.getItem('musicVolume')) : 1;
        this._isMuted = localStorage.getItem('isMuted') === 'true' || false;

        if (this._isMuted) {
            sound.muteAll();
        }

        this.musicMultipliers = 0.1;
        this.bgMultipliers = 0.1;
    }

    playMainMusic() {
        this.playMainThemeP1();
        this.backgroundSound = sound.play('backgroundSound', {loop: true, volume: this.musicVolume * this.bgMultipliers});
    }
    playMainThemeP1() {
        this.mainTheme = sound.play('mainThemeP1', {complete: this.playMainThemeP2.bind(this), volume: this.musicVolume * this.musicMultipliers});
    }

    playMainThemeP2() {
        this.mainTheme =  sound.play('mainThemeP2', {complete: this.playMainThemeP1.bind(this), volume: this.musicVolume * this.musicMultipliers});
    }

    playSWX(name) {
        sound.play(name, {loop: false, volume: this.swxVolume});
    }

    setSFXVolume(volume) {
        this.swxVolume = volume;
    }

    setMusicVolume(volume) {
        this.musicVolume = volume;
    }

    set swxVolume(volume) {
        this._swxVolume = volume;
        localStorage.setItem('swxVolume', volume);
    }

    get swxVolume() {
        return this._swxVolume;
    }

    set musicVolume(volume) {
        this._musicVolume = volume;
        this.mainTheme.volume = volume * this.musicMultipliers
        this.backgroundSound.volume = volume * this.bgMultipliers;

        localStorage.setItem('musicVolume', volume);
    }

    get musicVolume() {
        return this._musicVolume;
    }

    toggleMute() {
        sound.toggleMuteAll();
        this._isMuted = !this._isMuted;
        localStorage.setItem('isMuted', this._isMuted);
    }

    get isMuted() {
        return this._isMuted
    }
}
