import {GameState} from "../GameState";

export class CollectWinning extends GameState {
    async onEnter() {
        try {
            this.game.scene.kickButton.disable();
            this.game.scene.collectButton.disable();
            this.game.scene.betsLis.disable();

            await this.game.server.collectWinnings();

            if (this.game.settings.winAnimation) {
                this.game.soundPlayer.playSWX('winSound');
                await this.game.scene.playWinAnimation();
            }

            this.game.scene.setBalance(this.game.server.getBalanceText());
            this.game.fms.goTo('waitingForBet');
        } catch (e) {
            this.game.errorsHandler.showError(e);
        }
    }

    async onExit() {
    }
}
