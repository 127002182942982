import {Container} from "pixi.js";

export class GameState {
    constructor(name, game) {
        this.name = name;
        this.game = game;
        this.container = new Container();
    }

    async onEnter() {
        console.log(`Entering ${this.name} state`);
    }
    async onExit() {
        console.log(`Exiting ${this.name} state`);
    }

    exit() {}
}
