export class GameMath {
    constructor() {
        this.winProbability = 0.7; // Вероятность выигрыша
        this.multiplierRange = [0.25, 0.3, 0.5, 0.6,  0.75, 0.8, 1, 1.25, 1.5, 1.3]; // Диапазон увеличения множителя
    }

    // Генерация случайного числа в диапазоне [min, max]
    getRandomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    // Определение, является ли результат выигрышем или проигрышем
    isWin() {
        return Math.random() < this.winProbability;
    }

    // Генерация увеличения множителя в заданном диапазоне
    generateMultiplierIncrease() {
        return this.multiplierRange[Math.floor(Math.random() * this.multiplierRange.length)];
    }
}
