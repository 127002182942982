import {Container} from "pixi.js";
import {ObjectFactory} from "./ObjectFactory";
import {RotateMessageStyle} from "./configs/textStyles";

const messageText = `Please rotate your screen
for the better experience
`

export class RotateScreen extends Container {
    constructor(game) {
        super();

        this.background = ObjectFactory.createSpriteFromSheet('rotate.png');
        this.addChild(this.background);
        this.background.x = game.config.screenSize.width / 2;
        this.background.y = game.config.screenSize.height * 0.3;
        this.background.anchor.set(0.5);
        this.background.scale.set(2);

        this.message = ObjectFactory.createText(messageText, RotateMessageStyle);
        this.addChild(this.message);
        this.message.x = game.config.screenSize.width / 2;
        this.message.y = this.background.y + this.background.height / 2 + 80;
        this.message.anchor.set(0.5, 0);
    }
}
