import {Container, Sprite} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";

export class PageListSelector extends Container {
    constructor(numberOfPages, onChange) {
        super();

        const gap = 10;

        this.numberOfPages = numberOfPages;
        this.onChange = onChange;
        this.leftbutton = ObjectFactory.createArrowButton(this.decreasePage.bind(this));
        this.leftbutton.x = 0;
        this.leftbutton.y = -this.leftbutton.height / 2;
        this.addChild(this.leftbutton);

        this.currentPage = 0;
        this.indicators = [];

        for (let i = 0; i < this.numberOfPages; i++) {
            const indicator = new PageListIndicator()
            indicator.x =this.leftbutton.x + this.leftbutton.width + gap + (i * (indicator.width + gap));
            indicator.y = -indicator.height / 2;

            this.indicators.push(indicator);
            this.addChild(indicator);
        }

        this.rightbutton = ObjectFactory.createArrowButton(this.increasePage.bind(this));
        this.rightbutton.x = (this.indicators.length + 1 ) * (this.indicators[0].width + gap) + this.leftbutton.width + gap * 2;
        this.rightbutton.y = -this.rightbutton.height / 2;
        this.rightbutton.scale.x = -1;
        this.addChild(this.rightbutton);

        this.activateIndicator(this.currentPage);
    }

    decreasePage() {
        if (this.currentPage > 0) {
            this.currentPage--;
            this.activateIndicator(this.currentPage);
            this.onChange(this.currentPage);
        }
    }

    increasePage() {
        if (this.currentPage < this.numberOfPages - 1) {
            this.currentPage++;
            this.activateIndicator(this.currentPage);
            this.onChange(this.currentPage);
        }
    }

    activateIndicator(index) {
        for (let i = 0; i < this.indicators.length; i++) {
            this.indicators[i].active = i === index;
        }
    }
}

class PageListIndicator extends Container {
    constructor() {
        super();

        this.normalTexture = ObjectFactory.createSpriteFromSheet("listPoint.png").texture;
        this.activeTexture = ObjectFactory.createSpriteFromSheet("ListPointCheked.png").texture;

        this.srite = new Sprite(this.normalTexture);
        this.addChild(this.srite);
    }

    set active(value) {
        this.srite.texture = value ? this.activeTexture : this.normalTexture;
    }

    get active() {
        return this.srite.texture === this.activeTexture;
    }
}
