import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {Timer} from "../Timer";


const timeScale = 0.8;

export class Girl extends Container {
    constructor() {
        super();

        this.animationsByTarget =  ['Top6', 'onlyGirl', 'only_Ball'];
        this.spineAnimation = ObjectFactory.createSpineAnimation("girlSkeleton");
        this.addChild(this.spineAnimation);

        this.ballAnimation  = ObjectFactory.createAnimation('girl_ball/girl_ball');
        this.ballAnimation.visible = false;
        this.ballAnimation.x = -172;
        this.ballAnimation.y = -279;
        this.ballAnimation.loop = false;
        // this.spineAnimation.state.timeScale = timeScale;
        this.ballAnimation.animationSpeed *= timeScale;
        this.addChild(this.ballAnimation);
    }

    async playAnimation(animation, loop = false) {
        this.spineAnimation.state.setAnimation(0, animation, loop);

        return new Promise(resolve => this.spineAnimation.state.addListener({
            complete: () => {
                resolve();
            }
        }));
    }

    set animationSpeed(value) {
        this.spineAnimation.state.timeScale = value;
    }

    async play() {
        const animation = this.animationsByTarget[0];

        return this.playAnimation(animation);
    }

    async playIdle(){
        this.play();
        Timer.wait(3050 ).then(() => this.ballAnimation.visible = true);
        await Timer.wait(3200);
        await this.playBall();
        await Timer.wait(3000);
        this.playIdle();
    }

    async playBall(){
        this.ballAnimation.currentFrame = 0;
        this.ballAnimation.play();

        return new Promise(resolve => {
            this.ballAnimation.onComplete = () => {
                this.ballAnimation.currentFrame = 0;

                this.ballAnimation.visible = false;
                resolve();
            }
        });
    }
}
