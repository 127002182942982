import * as PIXI from 'pixi.js';
export * from '@pixi-spine/runtime-3.8';
import {gameConfig} from "./configs/gameConfig";
import {UrlParser} from "./URLParser";
import {GameApplication} from "./GameApplication";

window.WebFontConfig = {
    google: {
        families: ['Nunito'],
    },
    active: () =>  init()
};

(function () {
    const wf = document.createElement('script');
    wf.src = `${
        document.location.protocol === 'https:' ? 'https' : 'http'
    }://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js`;
    wf.type = 'text/javascript';
    wf.async = 'true';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
    wf.onerror = () => init()
})();

async function init() {
    const urlParser = new UrlParser();
    const app = new GameApplication({
        width: gameConfig.screenSize.width,
        height: gameConfig.screenSize.height,
        backgroundAlpha: 0,
        transparent: true,
        resolution: 2,
    }, {
        homeLink: urlParser.has('homeLink') ? urlParser.get('homeLink') : null,
        depositLink: urlParser.has('depositLink') ? urlParser.get('depositLink') : null,
        offline: urlParser.has('offline'),
        balance: urlParser.has('balance') ? Number(urlParser.get('balance')) : gameConfig.default.balance,
        session_uuid: urlParser.has('session') ? urlParser.get('session') : null,
        demo: urlParser.has('demo') ? urlParser.get('demo') : undefined,
        custom_message: urlParser.has('custom_message') ? urlParser.get('custom_message') : undefined,
        bets: gameConfig.default.betsList,
        ...gameConfig
    });

    await app.init();

    window.game = app;
    window.__PIXI_APP__ = app;
    window.__PIXI_STAGE__ = app.stage;
    window.__PIXI_RENDERER__ = app.renderer;
}
