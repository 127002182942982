import {GameState} from "../GameState";

export class WaitKickResult extends GameState {
    async onEnter() {
        const result =  this.game.server.roundResult;

        if (result.win) {
            this.game.fms.goTo('winRound');
        } else {
            this.game.fms.goTo('loseRound');
        }
    }

    async onExit() {
        this.game.scene.kickButton.disable();
    }
}
