import {GameState} from "../GameState";
import {Timer} from "../Timer";

export class WaitingForBet extends GameState {
    async onEnter() {
        this.game.scene.reset();
        this.game.server.reset();

        this.game.scene.placeBetButton.enable();
        this.game.scene.betsLis.enable();
        this.game.scene.placeBetButton.visible = true;
        this.game.scene.kickButton.visible = false;
        this.game.scene.collectButton.disable();
        this.game.scene.placeBetButtonHandler = this.onPlaceBet.bind(this);
        this.game.scene.targets.playAllTargetsAnimation();
        this.game.scene.targets.disable();
        this.game.scene.betButton.enable();
        this.game.scene.settingsButton.enable();
        this.game.scene.autoplayButton.enable();
        this.game.scene.infoButton.enable();

        if (this.game.settings.autoBet) {
            this.autoBet();
        }
    }

    async autoBet() {
        if (this.game.settings.autoplay) {
            if (this.game.settings.autoplay.rounds > 0) {
                this.game.settings.autoplay.rounds -= 1;
                this.game.scene.autoplayButton.setCounter(this.game.settings.autoplay.rounds === Infinity ? '∞' : this.game.settings.autoplay.rounds);
                await Timer.wait(this.game.config.autoplayClickTime);
                await this.game.scene.placeBetButton.click();
                this.game.fms.goTo('playRound');
            } else {
                this.game.settings.autoBet = false;
                this.game.scene.stopAutoplay();
                this.game.scene.autoplayButton.enable();
            }
        } else {
            this.game.settings.autoBet = false;
            this.game.scene.stopAutoplay();
            this.game.scene.autoplayButton.enable();
        }
    }

    onPlaceBet() {
        try {
            this.game.server.canSpin();
            this.game.fms.goTo('playRound');
        } catch (e) {
            this.game.errorsHandler.showError(e);
        }
    }

    async onExit() {
        this.game.scene.placeBetButton.disable();
        this.game.scene.collectButton.disable();
        this.game.scene.betsLis.disable();
        this.game.scene.betButton.disable();
        this.game.scene.settingsButton.disable();
        this.game.scene.autoplayButton.disable();
        this.game.scene.infoButton.disable();

        if (this.game.settings.autoBet) {
            this.game.scene.autoplayButton.enable();
        }
    }
}
