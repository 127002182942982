import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {ValueTween} from "../ValueTween";
import {TargetTextStyle} from "../configs/textStyles";

export class Target extends Container {
    constructor(id) {
        super();

        this.id = id;
        this.animation = ObjectFactory.createAnimation('3_1/3_1');
        this.animation.loop = false;
        this.addChild(this.animation);

        this.aim = ObjectFactory.createSprite('targetAim.png');
        this.aim.anchor.set(0.5);
        this.aim.x = this.animation.width / 2 + 1;
        this.aim.y = this.animation.height / 2 + 1;
        this.addChild(this.aim);

        this.aim.visible  = false;

        this.text =ObjectFactory.createText('x2.08', TargetTextStyle);
        this.text.anchor.set(0.5);
        this.text.x = this.animation.width / 2;
        this.text.y = this.animation.height / 2;
        this.text.visible = false;
        this.addChild(this.text);
        this.isEnabledInteractive = true;
    }

    enable() {
        this.interactive = true;
        this.buttonMode = true;
        this.cursor = 'pointer';

        if (!this.isEnabledInteractive) {
            this.disableInteractive()
        }
    }

    disable() {
        this.interactive = false;
        this.buttonMode = false;
        this.cursor = 'default';

        if (!this.isEnabledInteractive) {
            this.disableInteractive()
        }
    }

    async showWin(text) {
        this.text.text = text;
        this.text.visible = true;
        this.text.alpha = 0;
        this.aim.visible = false;
        this.animation.visible = false;

        return ValueTween.to(this.text, {key: 'alpha', to: 1}, 500);
    }

    async playAnimation() {
        this.animation.visible = true;
        this.animation.gotoAndPlay(0);
        await new Promise(resolve => this.animation.onComplete = resolve);
    }

    set animationSpeed(value) {
        this.animation.animationSpeed = value;
    }

    disableInteractive() {
        this.interactive = false;
        this.buttonMode = false;
        this.isEnabledInteractive = false;
    }

    enableInteractive() {
        this.interactive = true;
        this.buttonMode = true;
        this.isEnabledInteractive = true;
    }
}
