import {GameState} from "../GameState";

export class PlayRound extends GameState {
    async onEnter() {
        await this.game.scene.gameProgressBar.first();

        this.game.scene.placeBetButton.visible = false;

        try {
            if (this.game.server.isFirstRound()) {
                await this.game.server.placeBet();
                await this.game.scene.updateBalance(this.game.server.getBalanceText());
            }

            this.game.fms.goTo('waitKick');
        } catch (e) {
            this.game.errorsHandler.showError(e);
        }
    }
}
