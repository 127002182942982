import {Sprite, Container, NineSlicePlane} from 'pixi.js';
import {ButtonTextStyle} from "../configs/textStyles";
import {ObjectFactory} from "../ObjectFactory";

export class SliceButton extends Container {
    constructor({textures, onClick, text = '', bgScale = 1, textStyle = ButtonTextStyle,  nineSlicePlaneParameters, width}) {
        super();

        this.textures = textures;
        this.enabled = true;

        this.sprite = new NineSlicePlane(this.getDefaultTexture(), nineSlicePlaneParameters.left, nineSlicePlaneParameters.top, nineSlicePlaneParameters.right, nineSlicePlaneParameters.bottom);
        this.sprite.scale.set(bgScale);
        this.addChild(this.sprite);

        if (text) {
            this.text = ObjectFactory.createText(text, textStyle);
            this.text.anchor.set(0.5);
            this.sprite.width  = width || nineSlicePlaneParameters.left + this.text.width +  nineSlicePlaneParameters.right
            this.text.x = this.sprite.width / 2;
            this.text.y = this.sprite.height / 2;
            this.addChild(this.text);
        }

        this.sprite.interactive = true;
        this.sprite.buttonMode = true;
        this.sprite.cursor = 'pointer';

        this.sprite.on('pointerover', this.onHover.bind(this));
        this.sprite.on('pointerout', this.onOut.bind(this));
        this.sprite.on('pointerdown', this.onDown.bind(this));
        this.sprite.on('pointerup', this.onUp.bind(this));
        this.sprite.on('pointerupoutside', this.onUp.bind(this));

        this.setOnClick(onClick);
        this.isSelected = false;
    }

    onHover() {
        if (this.enabled) {
            this.sprite.texture = this.isSelected ? this.textures.selected : this.textures.hover;
        }
    }

    onOut() {
        if (this.enabled) {
            this.sprite.texture = this.getDefaultTexture();
        }
    }

    onDown() {
        if (this.enabled) {
            this.sprite.texture = this.isSelected ? this.textures.selected : this.textures.pressed;
        }
    }

    onUp() {
        if (this.enabled) {
            this.sprite.texture = this.isSelected ? this.textures.selected : this.textures.hover;
        }
    }

    setOnClick(onClick) {
        this.sprite.off('pointertap');

        if (onClick && typeof onClick === 'function') {
            this.sprite.on('pointertap', onClick);
        }
    }

    enable() {
        this.enabled = true;
        this.sprite.texture = this.getDefaultTexture();
        this.sprite.interactive = true;
        this.sprite.buttonMode = true;
    }

    disable() {
        this.enabled = false;
        this.sprite.texture = this.textures.disabled;
        this.sprite.interactive = false;
        this.sprite.buttonMode = false;
    }

    getDefaultTexture() {
        return this.isSelected ? this.textures.selected : this.textures.default;
    }

    setSelect(value) {
        this.isSelected = value;
        this.sprite.texture = this.getDefaultTexture();
    }
}
