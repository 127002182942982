import {Container} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {LevelMultiplierTextStyle, LevelNameTextStyle, LevelTextStyle} from "../configs/textStyles";
import {sound} from "@pixi/sound";

export class LevelCarousel extends Container {
    constructor(cups) {
        super();
        this.label = ObjectFactory.createText('Level', LevelTextStyle);
        this.label.anchor.set(0.5);

        this.addChild(this.label);

        this.carousel = ObjectFactory.createCarousel({
            elements: cups.map(cup => new Level(cup)),
            width: 200
        });
        this.carousel.on('selected', this.onSelect.bind(this));
        this.addChild(this.carousel);

        this.bg =  this.carousel.addChildAt(ObjectFactory.createSprite('LevelSelected.png'), 0);
        this.bg.anchor.set(0.5);

        this.label.y = - (this.carousel.height / 2 + 20);
    }

    getCurrentIndex() {
        return this.carousel.getCurrentIndex();
    }

    onSelect(index, element) {
        this.emit('selectLevel', index);
    }
}

class Level extends Container {
    constructor({texture, label, multiplier, locked = false}) {
        super();
        this.lockedTexture = ObjectFactory.getTextureFromSpriteSheet(`${texture}Locked.png`);
        this.level = ObjectFactory.createSpriteFromSheet(`${texture}.png`);
        this.level.anchor.set(0.5, 0.5);
        this.level.y = -14;
        this.addChild(this.level);

        this.levelName = ObjectFactory.createText(label, LevelNameTextStyle);
        this.levelName.anchor.set(0.5);
        this.levelName.y = this.level.height * 0.5;
        this.addChild(this.levelName);

        this.levelMultiplier = ObjectFactory.createText(`Multiplier X${multiplier}`, LevelMultiplierTextStyle);
        this.levelMultiplier.anchor.set(0.5);
        this.levelMultiplier.y = this.levelName.y + this.levelName.height / 2 + 8;
        this.addChild(this.levelMultiplier);

        if (locked) {
            this.lock();
        }
    }

    lock() {
        this.level.texture = this.lockedTexture;
        this.levelName.alpha = 0.5;
        this.levelMultiplier.alpha = 0.5;
        this.isLocked = true;
    }

    unlock() {
        this.level.alpha = 1;
        this.levelName.alpha = 1;
        this.levelMultiplier.alpha = 1;
        this.isLocked = false;
    }
}
