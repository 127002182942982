import {Container} from "pixi.js";

export class PopupManager extends Container {
    constructor(popups, game) {
        super();

        this.popupsConfig = popups;
        this.game = game;
        this.popups = {};
        this.currentPopup = null;
    }

    createPopups() {
        for (let name in this.popupsConfig) {
            this.popups[name] = new this.popupsConfig[name](this.game, name);
            this.popups[name].visible = false;
            this.addChild(this.popups[name]);
        }
    }

    showPopup(name, options) {
        if (this.currentPopup) {
            this.currentPopup.visible = false;
        }

        if (!this.popups[name]) {
            console.warn(`Popup with name "${name}" does not exist.`);
            return;
        }

        const newPopup = this.popups[name];

        newPopup.visible = true;
        newPopup.open(options);
        this.currentPopup = newPopup;
    }

    hidePopup(name) {
        if (this.popups[name]) {
            this.popups[name].visible = false;
        }
    }
}
