import { Container, Sprite } from 'pixi.js';
import { Button } from './Button.js';
import {ListLabelTextStyle, ListTextStyle} from "../configs/textStyles";
import {ObjectFactory} from "../ObjectFactory";

export class List extends Container {
    constructor({values, currentIndex, textures, label, labelTextStyle = ListTextStyle, valueTextStyle = ListLabelTextStyle, btnDx = 0}) {
        super();

        this.values = values;
        this.currentIndex = currentIndex;

        this.currentValueText = ObjectFactory.createText(this.values[this.currentIndex], valueTextStyle);
        this.currentValueText.anchor.set(0.5);
        this.currentValueText.y -= 2;
        this.addChild(this.currentValueText);

        this.background = new Sprite(textures.background);
        this.background.anchor.set(0.5);
        this.addChildAt(this.background, 0);

        this.leftButton = new Button({textures: textures.leftButton, onClick: this.decrease.bind(this)});
        this.leftButton.x = - (this.background.width / 2) + this.leftButton.width / 2;
        this.leftButton.y =  - (this.leftButton.height / 2);
        this.addChild(this.leftButton);

        this.rightButton = new Button({textures: textures.rightButton, onClick: this.increase.bind(this)});
        this.rightButton.x = this.background.width / 2 - this.leftButton.width / 2;
        this.rightButton.y = - (this.rightButton.height / 2);
        this.rightButton.scale.x = -1;
        this.addChild(this.rightButton);

        this.label = ObjectFactory.createText(label, labelTextStyle);
        this.label.anchor.set(0.5);
        this.label.y = -36;
        this.addChild(this.label);

        this.updateUI();
    }

    decrease() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            this.emit('change', this.currentIndex);
            this.updateUI();
        }
    }

    increase() {
        if (this.currentIndex < this.values.length - 1) {
            this.currentIndex++;
            this.emit('change', this.currentIndex);
            this.updateUI();
        }
    }

    setIndex(index) {
        if (index >= 0 && index < this.values.length) {
            this.currentIndex = index;
            this.updateUI();
        }
    }

    setValues(values, index) {
        this.values = values;
        this.currentIndex = index;
        this.updateUI();
        }

    getCurrent() {
        return this.values[this.currentIndex];
    }

    getCurrentIndex() {
        return this.currentIndex;
    }

    updateUI() {
        this.currentValueText.text = this.values[this.currentIndex];
        this.emit('changedValue', this.currentIndex);
    }

    disable() {
        this.leftButton.disable();
        this.rightButton.disable();
    }

    enable() {
        this.leftButton.enable();
        this.rightButton.enable();
    }

    disableInteractive() {
        this.leftButton.disableInteractive();
        this.rightButton.disableInteractive();
    }

    enableInteractive() {
        this.leftButton.enableInteractive();
        this.rightButton.enableInteractive();
    }
}
