import {RadioGroup} from "./RadioGroup";
import {ObjectFactory} from "../ObjectFactory";
import {SegmentedSelectTextStyle, SegmentedSelectTextStyleActive} from "../configs/textStyles";

export class SegmentedSelect extends RadioGroup {
    constructor({currentValue = 0, onSelect = () => {}} = {}) {
        const radioGroupOptions = {
            buttonTextures: {
                default: ObjectFactory.getTextureFromSpriteSheet('SegmentedControllButtonDefault.png'),
                hover:  ObjectFactory.getTextureFromSpriteSheet('SegmentedControllButtonHover.png'),
                pressed:  ObjectFactory.getTextureFromSpriteSheet('SegmentedControllButtonSelected.png'),
                disabled:  ObjectFactory.getTextureFromSpriteSheet('SegmentedControllButtonDefault.png'),
                selected: ObjectFactory.getTextureFromSpriteSheet( 'SegmentedControllButtonSelected.png'),
            },
            numberOfButtons: 3,
            onSelect,
            buttonTextStyle: SegmentedSelectTextStyle
        };
        super(radioGroupOptions);

        this.addChildAt(ObjectFactory.createSpriteFromSheet('SegmentedControllBackground.png'), 0);

        const buttonsText = ['Low', 'Medium', 'High'];

        this.buttons.forEach((button, index) => {
            button.x = 8 + index * button.width;
            button.y = 8;
            button.text.text = buttonsText[index];
            button.text.style = SegmentedSelectTextStyle;
        });

        this.selectedButton = currentValue;
        this.buttons[currentValue].setSelect(true);
    }

    selectButton(index) {
        super.selectButton(index);
        this.buttons.forEach((button, i) =>  button.text.style = SegmentedSelectTextStyle);
        this.buttons[index].text.style = SegmentedSelectTextStyleActive;
    }
}
