import { Container } from 'pixi.js';

export class GameSceneManager extends Container {
    constructor(game, scenes) {
        super();
        this.game = game;
        this.scenesConfig = scenes;
        this.scenes = {};
        this.currentScene = null;
        this.currentSceneName = null;
        this.previousScene = null;
    }

    showScene(name) {
        if (this.currentScene) {
            this.currentScene.visible = false;
            this.previousScene =  this.currentSceneName;
        }

        if (!this.scenes[name] && this.scenesConfig[name]) {
            this.scenes[name] = new this.scenesConfig[name](this.game);
            this.scenes[name].visible = false;
            this.addChild(this.scenes[name]);
        }

        const newScene = this.scenes[name];

        this.currentSceneName = name;

        if (newScene) {
            newScene.visible = true;
            this.currentScene = newScene;
        } else {
            console.warn(`Scene with name "${name}" does not exist.`);
        }

        return this.currentScene;
    }
    showPreviousScene() {
        if (this.previousScene) {
            this.showScene(this.previousScene);
        }
    }
}
