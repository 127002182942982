import {AnimatedSprite, Assets, Sprite, Text} from 'pixi.js';
import {Button} from './objects/Button';
import {BallButton} from "./objects/BallButton";
import {ProgressLine} from "./objects/ProgressLine";
import {GameLoadingProgressBar} from "./objects/GameLoadingProgressBar";
import {LoseAnimation} from "./objects/LoseAnimation";
import {List} from "./objects/List";
import {WinAnimation} from "./objects/WinAnimation";
import {LightAnimation} from "./objects/LightsAnimations";
import {Spine} from "pixi-spine";
import CheckBox from "./objects/CheckBox";
import {Slider} from "./objects/Slider";
import {Carousel} from "./objects/Carousel";
import {SliceButton} from "./objects/SliceButton";
import {AutoplayListTextStyle, ListTextStyle} from "./configs/textStyles";
import {CarouselSlider} from "./objects/CarouselSlider";

const allSheetName = 'assets/all-0.json';

export class ObjectFactory {
    constructor() {
        this.cache = Assets.cache;
    }

    createAnimation(name) {
        const frames = this.getAnimationFrames(name);

        if (!frames) {
            throw new Error(`Animation not found: ${name}`);
        }

        return AnimatedSprite.fromFrames(frames);
    }

    getAnimationFrames(name) {
        const sheet = this.cache.get('assets/sprite-animations-0.json');
        if (!sheet) {
            throw new Error(`Spritesheet not found: assets/sprite-animations-0.json`);
        }

        const frames = sheet.data.animations[name];
        if (!frames) {
            throw new Error(`Animation not found: ${name}`);
        }

        return frames;
    }

    getTextureFromSpriteSheet(name) {
            const sheet = this.cache.get(allSheetName);
            if (!sheet) {
                throw new Error(`Spritesheet not found: ${allSheetName}`);
            }

            const texture = sheet.textures[name];

            if (!texture) {
                throw new Error(`Texture not found: ${name}`);
            }

            return texture;
    }

    static getTextureFromSpriteSheet(name) {
        const sheet = Assets.cache

        if (!sheet) {
            throw new Error(`Spritesheet not found: ${allSheetName}`);
        }

        const texture = sheet.get(name);

        if (!texture) {
            throw new Error(`Texture not found: ${name}`);
        }

        return texture;
    }

    createSpriteFromSheet(name, sheetName = allSheetName) {
        const texture = this.cache.get(name);
        if (!texture) {
            throw new Error(`Texture not found: ${name}`);
        }

        return new Sprite(texture);
    }

    createSprite(name) {
        const texture = this.cache.get(`${name}`);

        if (!texture) {
            throw new Error(`Texture not found: assets/${name}`);
        }

        return new Sprite(texture);
    }

    createButton(texturesNames, onClick, text = '', bgScale = 1) {
        const sheet = this.cache.get(allSheetName);
        const textures = {
            default: this.cache.get(texturesNames.default),
            hover: this.cache.get(texturesNames.hover),
            pressed: this.cache.get(texturesNames.pressed),
            disabled: this.cache.get(texturesNames.disabled)
        };

        return new Button({textures, onClick, text, bgScale});
    }

   static createButton({texturesNames, onClick, text = '', bgScale = 1, textStyle}) {
        const sheet = Assets.cache;
        const textures = {
            default: sheet.get(texturesNames.default),
            hover: sheet.get(texturesNames.hover),
            pressed: sheet.get(texturesNames.pressed),
            disabled: sheet.get(texturesNames.disabled)
        };

        return new Button({textures, onClick, text, bgScale, textStyle});
    }

    static createSliceButton({texturesNames, onClick, text = '', bgScale = 1, textStyle, nineSlicePlaneParameters = {}, width}) {
        const sheet = Assets.cache;
        const textures = {
            default: sheet.get(texturesNames.default),
            hover: sheet.get(texturesNames.hover),
            pressed: sheet.get(texturesNames.pressed),
            disabled: sheet.get(texturesNames.disabled)
        };

        return new SliceButton({textures, onClick, text, bgScale, textStyle, nineSlicePlaneParameters, width});
    }

    createBallButton(texturesNames, animationName, onClick, text = '') {
        const sheet = this.cache.get(allSheetName);
        const textures = {
            default: this.cache.get(texturesNames.default),
            hover: this.cache.get(texturesNames.hover),
            pressed: this.cache.get(texturesNames.pressed),
            disabled: this.cache.get(texturesNames.disabled)
        };
        const animationFrames =  this.getAnimationFrames(animationName);

        return new BallButton(textures, onClick, text, animationFrames);
    }

    createBallAnimation(animationNumber) {
        const bollPostionsByTarget = [
            {x: 254, y: 370, id: 2},
            {x: 304, y: 172, id: 4},
            {x: 504, y: 338, id: 3},
            {x: 503, y: 167, id: 5},
            {x: 498, y: 332, id: 1},
        ]

        const ballAnimations = this.createAnimation(`2_${bollPostionsByTarget[animationNumber].id}/2_${bollPostionsByTarget[animationNumber].id}`);

        ballAnimations.x = bollPostionsByTarget[animationNumber].x;
        ballAnimations.y = bollPostionsByTarget[animationNumber].y;

        return ballAnimations;
    }

    createProgressLine() {

        return new ProgressLine();
    }

    createGameLoadingProgressBar() {
        const sheet = this.cache.get('assets/loading.json');

        if (!sheet) {
            throw new Error(`Spritesheet not found: assets/loading.json`);
        }

        const line = sheet.textures['ProgressLine.png'];
        const bg = sheet.textures['ProgressBackgound.png'];

        return new GameLoadingProgressBar(bg, line);
    }

    createLoseAnimation(screenSize) {
        return new LoseAnimation(this.getAnimationFrames('lose/lose'), screenSize, this.getTextureFromSpriteSheet('win-lose-bg.png'));
    }

    createWinAnimation(screenSize) {
        return new WinAnimation(this.getAnimationFrames('6_Congrats/6_Congrats'), screenSize, this.getTextureFromSpriteSheet('win-lose-bg.png'));
    }

        static createList(values, currentIndex, label, bg = 'InfoLableBackground.png', valueTextStyle = ListTextStyle) {
        const arrowButtonTextures = {
            default: this.getTextureFromSpriteSheet('ArrowButtonDefault.png'),
            hover: this.getTextureFromSpriteSheet('ArrowButtonHover.png'),
            pressed: this.getTextureFromSpriteSheet('ArrowButtonPressed.png'),
            disabled: this.getTextureFromSpriteSheet('ArrowButtonDisabled.png'),
        };

        return new List({
            values,
            currentIndex,
            label,
            valueTextStyle,
            textures: {
                leftButton: arrowButtonTextures,
                rightButton: arrowButtonTextures,
                background: this.getTextureFromSpriteSheet(bg)
            }
        });
    }

    static createListForAutoplaySettings(values, currentIndex, label) {
        const arrowButtonTextures = {
            default: ObjectFactory.getTextureFromSpriteSheet('ArrowButtonDefault.png'),
            hover: ObjectFactory.getTextureFromSpriteSheet('ArrowButtonHover.png'),
            pressed: ObjectFactory.getTextureFromSpriteSheet('ArrowButtonPressed.png'),
            disabled: ObjectFactory.getTextureFromSpriteSheet('ArrowButtonDisabled.png'),
        };

        return new List({
            values,
            currentIndex,
            label,
            valueTextStyle: AutoplayListTextStyle,
            textures: {
                leftButton: arrowButtonTextures,
                rightButton: arrowButtonTextures,
                background: ObjectFactory.getTextureFromSpriteSheet('AutoplayValueBG.png')
            }
        });
    }

    createLightAnimation() {
        return new LightAnimation(this.getAnimationFrames('3_3/3_3'));
    }

    static createText(text, style, resolution = 2) {
        const  textObject =  new Text(text, style);

        textObject.resolution = resolution;

        return textObject;
    }

    static createAnimation(name) {
        const cache = Assets.cache;
        const sheet = cache.get('assets/sprite-animations-0.json');

        if (!sheet) {
            throw new Error(`Spritesheet not found: assets/sprite-animations-0.json`);
        }

        const frames = sheet.data.animations[name];

        if (!frames) {
            throw new Error(`Animation not found: ${name}`);
        }

        return AnimatedSprite.fromFrames(frames);
    }

    static createSprite(name) {
        const cache = Assets.cache;
        const texture = cache.get(name);

        if (!texture) {
            throw new Error(`Texture not found: ${name}`);
        }

        return new Sprite(texture);
    }

    static createSpriteFromSheet(name, sheetName = allSheetName) {
        const cache = Assets.cache;

        const texture = cache.get(name);

        if (!texture) {
            throw new Error(`Texture not found: ${name}`);
        }

        return new Sprite(texture);
    }

    static createSpineAnimation(name) {
        const cache = Assets.cache;
        const spine = cache.get(name);

        if (!spine) {
            throw new Error(`Spine not found: ${name}`);
        }

        return new Spine(spine.spineData);
    }

    static createArrowButton(onClick) {
        const cache = Assets.cache;
        const textures = {
            default: cache.get('ArrowButtonDefault.png'),
            hover: cache.get('ArrowButtonHover.png'),
            pressed: cache.get('ArrowButtonPressed.png'),
            disabled: cache.get('ArrowButtonDisabled.png')
        };

        return new Button({textures, onClick});
    }

    static createCheckBox(text, initialChecked = false) {
        const cache = Assets.cache;
        const textures = {
            checked: cache.get('CheckboxCheked.png'),
            unchecked: cache.get('CheckboxUncheked.png'),
        };

        return new CheckBox(textures, text, initialChecked);
    }

    static createSlider() {
        const cache = Assets.cache;
        const textures = {
            lineTexture: cache.get('SliderLine.png'),
            knobTexture: cache.get('knob.png'),
            bgTexture: cache.get('SliderBackgroud.png'),
        };

        return new Slider(textures.lineTexture, textures.knobTexture, textures.bgTexture);
    }

    static createCarousel({elements, width, height,}) {
        return new CarouselSlider(
            elements,
            {
                default: ObjectFactory.getTextureFromSpriteSheet('ArrowButtonDefault.png'),
                hover:  ObjectFactory.getTextureFromSpriteSheet('ArrowButtonHover.png'),
                pressed:  ObjectFactory.getTextureFromSpriteSheet('ArrowButtonPressed.png'),
                disabled:  ObjectFactory.getTextureFromSpriteSheet('ArrowButtonDisabled.png'),
            },
            width,
            height
        );
    }
}
