import {BasePopup} from "./BasePopup";
import {ObjectFactory} from "../ObjectFactory";
import {SessionExpiredPopupTextStyle} from "../configs/textStyles";

const TextMessage = `Your session is expired,
please reload the page`;

export class SessionExpiredPopup extends BasePopup {
    constructor(game, name, onClose = () => {
    }) {
        super(game, name, onClose);

        this.label.text = '';

        this.homeLink = this.game.config.homeLink;
        this.bg.texture = ObjectFactory.getTextureFromSpriteSheet('SessionExpiredPopupBG.png');

        this.message = ObjectFactory.createText(TextMessage, SessionExpiredPopupTextStyle);
        this.message.x = this.bg.x + this.bg.width / 2;
        this.message.y = this.bg.y + this.bg.height * 0.7;
        this.message.anchor.set(0.5);
        this.addChild(this.message);

        if (this.homeLink) {
            this.button = this.game.objectFactory.createButton({
                default: 'ButtonNormal.png',
                hover: 'ButtonHover.png',
                pressed: 'ButtonSelected.png',
                disabled: 'ButtonDisabled.png',
            }, () => this.reload(), 'Reload', 0.6);
            this.button.x = this.bg.x + this.bg.width / 2 - this.button.width / 2;

            this.addChild(this.button);

            this.message.y = this.bg.y + this.bg.height * 0.6;
            this.button.y = this.bg.y + this.bg.height * 0.75;
        }

        this.closeButton.visible = false
    }

    onClose() {
        this.reload();
    }

    reload() {
        if (this.homeLink) {
            this.game.postMessage(`goToHomePage#${this.homeLink}`);
        }
    }
}

