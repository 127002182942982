export const manifest = {
    bundles: [
        {
            name: 'load-screen',
            assets: [
                {
                    alias: 'bg',
                    src: 'assets/images/bg.png'
                },
                {
                    alias: 'loading-json',
                    src: 'assets/loading.json'
                },
            ],
        },
        {
            name: 'game-screen',
            assets: [
                {
                    alias: 'sprite-animations-0',
                    src: 'assets/sprite-animations-0.json'
                },
                {
                    alias: 'all-json',
                    src: 'assets/all-0.json'
                },
                {
                    alias: "girlAtlas",
                    src: 'assets/spine/Girl.atlas'
                },
                {
                    alias: "girlSkeleton",
                    src: 'assets/spine/Girl.json'
                },
                {
                    alias: "goalkeeperAtlas",
                    src: 'assets/spine/goalkeeper/goalkeeper.atlas'
                },
                {
                    alias: 'goalkeeperSkeleton',
                    src: 'assets/spine/goalkeeper/goalkeeper.json'
                },
                {
                    alias: 'mainThemeP1',
                    src: 'assets/audio/1.0_part_3.mp3'
                },
                {
                    alias: 'mainThemeP2',
                    src: 'assets/audio/1.0_part_6.mp3'
                },
                {
                    alias: 'scoredGoalSound',
                    src: 'assets/audio/2.0_ver_4.mp3'
                },
                {
                    alias: 'backgroundSound',
                    src: 'assets/audio/4.0_ver_1.mp3'
                },
                {
                    alias: 'swipeSliderSound',
                    src: 'assets/audio/6.0_ver_3.mp3'
                },
                {
                    alias: 'placeBetSound',
                    src: 'assets/audio/7.0_ver_2.mp3'
                },
                {
                    alias: 'playButtonSound',
                    src: 'assets/audio/9.0_ver_1.mp3'
                },
                {
                    alias: 'buttonClickSound',
                    src: 'assets/audio/9.1_ver_2.mp3'
                },
                {
                    alias: 'kickBallSound',
                    src: 'assets/audio/14.0.mp3'
                },
                {
                    alias: 'winSound',
                    src: 'assets/audio/15.0.mp3'
                },
                {
                    alias: 'loseSound',
                    src: 'assets/audio/16.0.mp3'
                }
            ],
        },
    ],
};
