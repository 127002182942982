import {Container, NineSlicePlane} from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";
import {Girl} from "../objects/Girl";
import {CountrySlider} from "../objects/CountrySlider";
import {LevelCarousel} from "../objects/LevelSlider";
import {LobbyHeadersTextStyle} from "../configs/textStyles";
import {BaseGameScene} from "./BaseGameScene";

export class Lobby extends BaseGameScene {
    constructor(game) {
        super(game);

        this.currentLevel = -1;

        this.girl = new Girl();
        this.addChild(this.girl);
        this.girl.playIdle();
        this.girl.scale.set(0.8);
        this.girl.x = 150;
        this.girl.y = this.game.config.screenSize.height;

        this.letsPlayButton = this.addChild(ObjectFactory.createButton({
            texturesNames: {
                default: 'ButtonNormal.png',
                hover: 'ButtonHover.png',
                pressed: 'ButtonSelected.png',
                disabled: 'ButtonDisabled.png'
            },
            text: 'Lets play',
            onClick: this.letsPlay.bind(this)
        }));
        const gap = 200;

        this.levels = this.addChild(new LevelCarousel(this.game.config.cups));
        this.levels.x = this.game.config.screenSize.width / 2;
        this.levels.y = this.game.config.screenSize.height / 2;
        this.levels.scale.set(0.8);
        this.levels.on('selectLevel', this.onSelectLevel.bind(this));

        this.letsPlayButton.scale.set(0.7);
        this.letsPlayButton.x = this.game.config.screenSize.width / 2 - this.letsPlayButton.width / 2;
        this.letsPlayButton.y = this.game.config.screenSize.height / 2 + 150;

        this.counties = this.addChild(new CountrySlider(this.game.config.counties));
        this.counties.x = this.game.config.screenSize.width / 2;
        this.counties.y = this.game.config.screenSize.height / 2 - gap;
        this.counties.scale.set(0.8);
        this.counties.on('selected', this.onSelectCountry.bind(this));

        this.lock = this.addChild(new Container());
        this.lockIcon = this.lock.addChild(ObjectFactory.createSpriteFromSheet('LockIcon.png'));
        this.lockIcon.anchor.set(0.5);

        this.locakedLabel = this.lock.addChild(ObjectFactory.createText('Level Locked!', LobbyHeadersTextStyle));
        this.locakedLabel.anchor.set(0.5);
        this.locakedLabel.y = this.lockIcon.height / 2 + 12;

        this.locakedLabelB = this.lock.addChild(ObjectFactory.createText('You need to play more games at the previous level', LobbyHeadersTextStyle));
        this.locakedLabelB.anchor.set(0.5);
        this.locakedLabelB.y = this.locakedLabel.y + 20;
        this.locakedLabelB.alpha = 0.6;

        this.lock.x = this.letsPlayButton.x + this.letsPlayButton.width / 2;
        this.lock.y = this.letsPlayButton.y;

        this.selectLevel(this.levels.getCurrentIndex());
        this.selectCountry(this.counties.getCurrentIndex());
    }

    onSelectCountry(index) {
        this.selectCountry(index);
        this.game.soundPlayer.playSWX('swipeSliderSound');
    }

    onSelectLevel(index) {
        this.selectLevel(index);
        this.game.soundPlayer.playSWX('swipeSliderSound');
    }

    selectLevel(levelIndex) {
        this.lock.visible = this.game.config.cups[levelIndex].locked;
        this.letsPlayButton.visible = !this.game.config.cups[levelIndex].locked;
        this.currentLevel = levelIndex;
    }

    selectCountry(index) {
        this.game.settings.country = this.game.config.counties[index];
    }

    letsPlay() {
        this.game.fms.goTo('waitingForBet');
        this.game.settings.level =  this.currentLevel;

        this.game.soundPlayer.playSWX('buttonClickSound');
    }
}
