import {GameState} from "../GameState";
import {Assets} from "pixi.js";
import {manifest} from "../configs/assets-manifest";
import { loadTextures } from '@pixi/assets';

loadTextures.config = {
    preferWorkers: true,
    preferCreateImageBitmap: true,
    rossOrigin: 'anonymous',
};
export class Loading extends GameState {
   async onEnter() {
       try {
           await Assets.init({manifest, texturePreference: {resolution: 4}});
           await Assets.loadBundle(['load-screen']);
       } catch (e) {
           this.game.errorsHandler.showError(e);
       }

       this.game.addRotateScreen();
       this.game.showRotateSceneIfNeeded();
       this.game.scene =  this.game.scenes.showScene('loading');

        Assets.loadBundle(['game-screen'], (progress) => {
            this.game.scene.setLoadingProgress(progress);
        });
    }

    async exit() {
        this.game.popupManager.createPopups();

        try {
            await this.game.server.init();

            if (this.game.server.inProgress()) {
                this.game.fms.goTo('restore');
            } else {
                this.game.fms.goTo('selectLevel');
            }
        } catch (e) {
            this.game.errorsHandler.showError(e);
        }
    }
}
