import {Timer} from "./Timer";

export class ValueTween {
    static timeScale = 1;
    static easingFunctions = {
        linear: t => t,
        easeInQuad: t => t * t,
        easeOutQuad: t => t * (2 - t),
        easeInOutQuad: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
        easeInCubic: t => t * t * t,
        easeOutCubic: t => (--t) * t * t + 1,
        easeInOutCubic: t => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
        elasticIn: t => t === 0 ? 0 : t === 1 ? 1 : -Math.pow(2, 10 * (t - 1)) * Math.sin((t - 1.1) * 5 * Math.PI),
        elasticOut: t => t === 0 ? 0 : t === 1 ? 1 : Math.pow(2, -10 * t) * Math.sin((t - 0.1) * 5 * Math.PI) + 1,
        elasticInOut: t => t === 0 ? 0 : t === 1 ? 1 : t < 0.5 ? -(Math.pow(2, 10 * (2 * t - 1)) * Math.sin((2 * t - 1.1) * 5 * Math.PI)) / 2 : Math.pow(2, -10 * (2 * t - 1)) * Math.sin((2 * t - 1.1) * 5 * Math.PI) / 2 + 1,
    };

     static async to(object, { key, to, from, setter, delay = 0 }, time, easing = 'linear') {
        if (delay > 0) {
            await Timer.wait(delay);
        }

        await (new Promise((resolve) => {
            const startValue = from !== undefined ? from : object[key];
            const change = to - startValue;
            const startTime = performance.now();
            const easingFunction = ValueTween.easingFunctions[easing];

            function update() {
                const currentTime = performance.now();
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / time, 1);
                const easedProgress = easingFunction(progress);
                const value = startValue + change * easedProgress;

                if (setter) {
                    setter(value);
                } else {
                    object[key] = value;
                }

                if (progress < 1) {
                    requestAnimationFrame(update);
                } else {
                    resolve();
                }
            }

            requestAnimationFrame(update);
        }));
    }
}

export default ValueTween;
