import { Container } from 'pixi.js';
import { Button } from './Button.js';

export class RadioGroup extends Container {
    constructor({buttonTextures, numberOfButtons, onSelect = () => {}, buttonTextStyle}) {
        super();
        this.buttons = [];
        this.selectedButton = null;
        this.onSelect = onSelect;

        this.createButtons(buttonTextures, numberOfButtons, buttonTextStyle);
    }

    createButtons(textures, numberOfButtons = 0, textStyle) {
        for (let index = 0; index < numberOfButtons; index += 1) {
             const button = new Button({
                 textures,
                 onClick: () => this.selectButton(index),
                 text: `Button ${index + 1}`,
                 textStyle
            }
        );
            button.y = index * button.height;
            this.addChild(button);
            this.buttons.push(button);
        }
    }

    forEachButton(callback) {
        this.buttons.forEach(callback);
    }

    selectButton(index) {
        if (this.selectedButton !== null) {
            this.buttons[this.selectedButton].setSelect(false);
        }

        this.selectedButton = index;
        this.buttons[index].setSelect(true);
        this.onSelect(index);
    }

    getSelectedButton() {
        return this.selectedButton;
    }

    disableButton(index) {
        if (index >= 0 && index < this.buttons.length) {
            this.buttons[index].disable();
        }
    }

    enableButton(index) {
        if (index >= 0 && index < this.buttons.length) {
            this.buttons[index].enable();
        }
    }
}
