import {GameState} from "../GameState";

export class SelectBet extends GameState {
    async onEnter() {
        this.game.popupManager.showPopup('Bets');
        this.game.scene.showBlur();
    }

    async onExit() {
        this.game.scene.betsLis.setIndex(this.game.server.currentBetIndex);
        this.game.scene.hideBlur();
    }
}
