import {GameState} from "../GameState";
import {getRandomNumber} from "../utils";
import {Timer} from "../Timer";

export class WaitKick extends GameState {
    async onEnter() {
        this.game.scene.kickButton.visible = true;
        this.game.scene.targets.visible = true;
        this.game.scene.showTargets();
        this.game.scene.targets.enable();

        if (!this.game.settings.autoBet) {
            this.game.scene.infoButton.enable();
            this.game.scene.settingsButton.enable();
            this.game.scene.kickButton.enable();
        }

        if (!this.game.server.isFirstRound()) {
            this.game.scene.targets.playAllTargetsAnimation();
        }

        this.game.scene.goalkeeper.onTheSpot();

        if (!this.game.server.isFirstRound()) {
            this.game.scene.collectButton.enable();
        }

        this.game.scene.kickButtonHandler = this.selectRandomTarget.bind(this);
        this.game.scene.targetHandler = this.selectTarget.bind(this);
        this.game.scene.collectButtonHandler = this.collectWinning.bind(this);
        this.game.scene.kickButton.enable();

        if (this.game.settings.autoBet) {
            this.autoPlay();
        }
    }

    async autoPlay() {
        await Timer.wait(this.game.config.autoplayClickTime);

        const {cashOut, multiplier} = this.game.settings.autoplay;

        if (cashOut) {
            if (this.game.server.getCurrentMultiplier() >= multiplier) {
                await this.game.scene.collectButton.click();
                this.collectWinning();
                return;
            }
        }

        await this.selectRandomTarget();
    }

    async onExit() {
        this.game.scene.kickButton.disable();
        this.game.scene.collectButton.disable();
        this.game.scene.infoButton.disable();
        this.game.scene.settingsButton.disable();
        this.game.scene.targets.disable();
    }

    async selectRandomTarget() {
        await this.selectTarget(getRandomNumber());
    }

    async selectTarget(target) {
        try {
            await this.game.scene.selectTarget(target);
            this.game.scene.kickButton.visible = true;
            this.game.scene.kickButton.disable();
            this.game.scene.collectButton.disable();
            await this.game.server.playRound(target);
            this.game.soundPlayer.playSWX('kickBallSound');
            this.game.scene.kickButton.visible = false;
            await this.game.scene.kickBallToTarget(target, this.game.server.roundResult.win, this.game.server.currentMultiplier);

            this.game.fms.goTo('waitKickResult');
        } catch (e) {
            this.game.errorsHandler.showError(e);
        }
    }

    async collectWinning() {
        this.game.fms.goTo('collectWinning');
    }
}
