import {GameState} from "../GameState";

export class LoseRound extends GameState {
    async onEnter() {
        if (this.game.settings.winAnimation) {
            this.game.soundPlayer.playSWX('loseSound');
            await this.game.scene.playLoseAnimation();
        }
        this.game.scene.setWin('');
        this.game.scene.reset();
        this.game.fms.goTo('waitingForBet');
    }

    async onExit() {
    }
}
