export class FMS {
    constructor(game, states) {
        this.game = game;
        this.states = {};
        this.currentState = null;
        this.previousState = null;
        this.addStates(states);
    }

    addStates(states) {
        for (const name in states) {
            this.addState(name, states[name]);
        }
    }

    addState(name, stateConstructor) {
        this.states[name] = new stateConstructor(name, this.game);
    }

    async changeState(newState) {
        if (this.currentState) {
            await this.states[this.currentState].onExit();
        }

        this.previousState = this.currentState;
        this.currentState = newState;

        if (this.currentState) {
            await this.states[this.currentState].onEnter();
        }
    }

    goTo(state) {
        this.changeState(state);
    }

    goToPrevious() {
        this.changeState(this.previousState);
    }

    exit() {
        if (this.currentState) {
            this.states[this.currentState].exit();
        }
    }
}
