import {Container} from "pixi.js";
import {Spine} from "pixi-spine";
import * as PIXI from "pixi.js";
import {ObjectFactory} from "../ObjectFactory";

export class Goalkeeper extends Container {
    constructor() {
        super();

        this.animationsByTarget =  ['Down_Left', 'Top_Left', 'UP',  'Top_Right', 'Down_Right'];
        this.spineAnimation = ObjectFactory.createSpineAnimation("goalkeeperSkeleton");
        this.addChild(this.spineAnimation);
    }

    async onTheSpot() {
        const randomAnimation = ['On_the_spot1', 'On_the_spot2'];
        const animation = randomAnimation[Math.floor(Math.random() * randomAnimation.length)];

        return this.playAnimation(animation);
    }

    async catchTheBall(targetNumber) {
        const animation = this.animationsByTarget[targetNumber];

        return this.playAnimation(animation);
    }

    async missTheBall(targetNumber) {
        const animations = this.animationsByTarget.filter((_, index) => index !== targetNumber);
        const animation = animations[Math.floor(Math.random() * animations.length)];

        return this.playAnimation(animation);
    }

    async playAnimation(animation) {
        this.spineAnimation.state.setAnimation(0, animation, false);

        return new Promise(resolve => this.spineAnimation.state.addListener({
            complete: () => {
                resolve();
            }
        }));
    }

    set animationSpeed(value) {
        this.spineAnimation.state.timeScale = value;
    }
}
