export class SmoothingProgress {
    constructor(onChange, onComplete) {
        this.onChange = onChange;
        this.onComplete = onComplete;
        this.currentProgress = 0;
        this.targetProgress = 0;
        this.isAnimating = false;
    }

    increase(value) {
        if (value > this.targetProgress) {
            this.targetProgress = value;
            if (!this.isAnimating) {
                this.isAnimating = true;
                this.animate();
            }
        }
    }

    animate() {
        if (this.currentProgress < this.targetProgress) {
            this.currentProgress += 1;
            if (this.currentProgress > this.targetProgress) {
                this.currentProgress = this.targetProgress;
            }
            this.onChange(this.currentProgress);
            requestAnimationFrame(() => this.animate());
        } else {
            this.isAnimating = false;
            if (this.currentProgress === 100 && this.onComplete) {
                this.onComplete();
            }
        }
    }
}
