import {Connection} from "./Connection";
import {GameMath} from "./GameMath";
import {ErrorMessageError} from "./errors/ErrorMessageError";

export class OfflineMode extends Connection {
    constructor(options) {
        super(options);

        this.gameMath = new GameMath();
        this.bet = 0;
    }

    async init() {}

    async placeBet() {
        await super.placeBet();

        const betAmount = this.getBet();

        this.reset();

        this.bet = betAmount;
        this.balance -= betAmount;
    }

    restore(balance, bet, rounds) {
        super.restore(balance, bet, rounds);

        this.bet = bet;
    }

    async playRound(targetNumber) {
        if (this.winStreak >= 6) {
            throw new Error('Maximum win streak reached. Collect your winnings.');
        }

        if (this.custom_message) {
            throw new ErrorMessageError(this.custom_message);
        }

        const isWin = this.gameMath.isWin();
        let multiplierIncrease = 0;
        let winnings = 0;

        if (isWin) {
            multiplierIncrease = this.gameMath.generateMultiplierIncrease();
            winnings = this.bet * multiplierIncrease;
        }

        this.applyRoundResult(isWin, targetNumber, multiplierIncrease, winnings);
    }

    async collectWinnings() {
        const winnings =  this.roundWin ;

        this.balance += winnings;

        return winnings;
    }
}
