import {roundToTwo} from "./utils";
import {NotEnoughFundsError} from "./errors/NotEnoughFundsError";
import {ErrorMessageError} from "./errors/ErrorMessageError";

export class Connection {
    constructor({balance, game, custom_message = undefined}) {
        this.game = game;
        this.balance = balance;
        this.currentMultiplier = 1;
        this.winStreak = 1;
        this.roundTarget = -1;
        this.roundResult = null;
        this.defaultBetsList = [0.05, 0.25, 0.5, 1, 2, 2.5, 5, 7.5, 10, 25, 50, 100];
        this.betsList =this.defaultBetsList;
        this.currentBetIndex = 0;
        this.roundWin = 0;
        this.rounds = null;
        this.custom_message = custom_message;
    }

    async placeBet() {
        const betAmount = this.getBet();

        if (betAmount > this.balance) {
            throw new NotEnoughFundsError('Insufficient balance to place the bet');
        }

        if (this.custom_message) {
            throw new ErrorMessageError(this.custom_message);
        }
    }

    applyRoundResult(isWin,target, multiplier = 0, winnings = 0) {
        if (isWin) {
            this.winStreak += 1;
            this.currentMultiplier = roundToTwo(this.currentMultiplier + multiplier);
            this.roundTarget = target;
            this.roundWin += winnings;

            this.roundResult = {
                win: isWin,
                multiplier: this.currentMultiplier,
                winnings :winnings,
                winStreak: this.winStreak,
                target
            };
        } else {
            this.winStreak = 1;
            this.currentMultiplier = 0;
            this.roundTarget = -1;

            this.roundResult =  {
                win: false,
                multiplier: 0,
                winnings: 0,
                winStreak: this.winStreak,
                target
            };
        }
    }

    restore(balance, bet, rounds) {
        this.currentBetIndex = this.betsList.indexOf(bet);
        this.balance = balance;
        this.rounds = rounds;
        this.winStreak = rounds.length + 1;
        this.currentMultiplier = roundToTwo(rounds.reduce((a, r) => a + r.multiplier, 0));
        this.roundWin = rounds.reduce((a, r) => a + r.win, 0);

        if (rounds.length > 0) {
            this.roundResult = {
                win: true,
                multiplier: this.currentMultiplier,
                winnings: this.roundWin,
                winStreak: this.winStreak,
                target: 1
            };
        }
    }

    setBetIndex(index) {
        if (index >= 0 && index < this.betsList.length) {
            this.currentBetIndex = index;
        }
    }

    getBet() {
        return this.betsList[this.currentBetIndex];
    }

    getBalance() {
        return this.balance;
    }

    getBalanceText() {
        return `$${this.balance.toFixed(2)}`;
    }

    getCurrentMultiplier() {
        return this.currentMultiplier;
    }

    getCurrentWinStreak() {
        return this.winStreak;
    }

    isFirstRound() {
        return this.winStreak === 1;
    }

    isLastRound() {
        return this.winStreak > 5;
    }

    inProgress() {
        return this.rounds  !== null;
    }

    /**
     *
     * @returns {Promise<Awaited<{balance: number}>>}
     */
    async init() {
        throw new Error('Not implemented');
    }
    /**
     *
     * @param data {Object} \{bet: number, multiplier: number\}
     * @returns {Promise<Awaited<{Object}>>} \{win: boolean, multiplier: number, winnings: number\}
     */
    async ballKick(data) {
        return Promise.resolve(data);
    }

    reset() {
        this.currentMultiplier = 0;
        this.winStreak = 1;
        this.roundTarget = -1;
        this.roundResult = null;
        this.roundWin = 0;
        this.rounds = null;
    }

    canSpin() {
        const betAmount = this.getBet();

        if (betAmount > this.balance) {
            throw new NotEnoughFundsError('Insufficient balance to place the bet');
        }
    }
}
