import {ObjectFactory} from "../ObjectFactory";
import {BasePopup} from "./BasePopup";
import {PageListSelector} from "../objects/PageListSelector";
import {Container} from "pixi.js";

export class Info extends BasePopup {
    constructor(game, name, onClose = () => {}) {
        super(game, name, onClose);

        const pages  = 5;
        this.pages = [];

        this.bg.visible = false;
        this.pageContainer = new Container();
        this.addChildAt(this.pageContainer, 0);

        for (let i = 0; i < pages; i++) {
            const page = ObjectFactory.createSpriteFromSheet(`InfoPage${i + 1}.png`);
            page.visible = false;
            page.x = game.config.screenSize.width / 2 - page.width / 2;
            page.y = game.config.screenSize.height / 2 - page.height / 2;

            this.pages.push(page);

            this.pageContainer.addChild(page);
        }

        this.label.text = '';

        this.showPage(0);

        this.pageListSelector = new PageListSelector(pages, this.showPage.bind(this));
        this.pageListSelector.x = game.config.screenSize.width / 2 - this.pageListSelector.width / 2;
        this.pageListSelector.y = this.pages[0].y + this.pages[0].height + 20;
        this.addChild(this.pageListSelector);
    }

    showPage(index) {
        for (let i = 0; i < this.pages.length; i++) {
            this.pages[i].visible = i === index;
        }

        this.game.soundPlayer.playSWX('swipeSliderSound');
    }

    get currentPage() {
        for (let i = 0; i < this.pages.length; i++) {
            if (this.pages[i].visible) {
                return i;
            }
        }
    }

    close() {
        super.close();
        this.game.fms.goToPrevious();
    }
}
